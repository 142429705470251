@charset "utf-8";

/* CSS Document */

/*

font-family: 'Roboto', sans-serif;
--------------------------------
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

thin 100
thin 100 Italic
light 300
light 300 Italic
regular 400
regular 400 Italic
medium 500
medium 500 Italic
bold 700
bold 700 Italic
black 900
black 900 Italic


font-family: 'Roboto Slab', serif;
--------------------------------
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700');

thin 100
light 300
regular 400
bold 700


*/

/*-----------------------------------------*/

html,
body {
    height: 100%;
}
body {
    margin: 0;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #333333;
    overflow-x: hidden;
}
a {
    text-decoration: none;
    color: #333;
    transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
}
a:hover {
    color: #DB3C00;
    text-decoration: underline;
}
a:focus {
    outline: none;
    text-decoration: none;
    color: inherit;
}
:focus {
    outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 15px 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #241849;
}
h1 {
    font-size: 36px;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 24px;
}
h4 {
    font-size: 22px;
}
h5 {
    font-size: 20px;
}
h6 {
    font-size: 18px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    text-decoration: none;
}
p {
    margin: 0 0 20px 0;
    line-height: 27px;
    padding-top: 15px;
}
ul,
ol {
    margin: 0;
}
strong,
b {}
/*----------------------------------------------*/

.headermenulogosec {
    padding: 35px 0px;
}
/*.sticky {position:relative !important; width:100%; top:0px; z-index:999; background-color:#fff !important; }
.sticking {background-color:#fff; width: 100%;  animation: fadeInDown 0.7s ease-in-out; -webkit-animation: fadeInDown 0.7s ease-in-out;  position:fixed !important; z-index:9999;}
.sticking.topheader {padding-top:15px; padding-bottom:0px;}
.sticking .mainlogo {top: -8px;}*/

/*.sticky.hometopheader.sticking { position:fixed !important; background-color:#fff !important;}*/

.bannersection {
    background: url(3ff70f85ba80117c8c24d31df546ac95.jpg) no-repeat top right;
    height: 767px;
}
.bannersection .container {
    position: relative;
    height: 100%;
}
.bannerposab h1 {
    font-family: 'Roboto Slab', serif;
    color: #4D00C7;
    font-weight: 300;
}
.banndet-text {
    margin-bottom: 10px;
}
.bannerbluetext {
    margin-bottom: 10px;
    font-weight: 700;
    color: #4d00c7;
    font-size: 20px;
}
.bannerbluetext a {
    color: #4d00c7;
}
.bannerbluetext a:hover {
    color: #DB3C00;
}
.banner-pos-rel {
    position: relative;
    height: 578px;
    max-width: 450px;
}
.bannerposab {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -khtml-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
}
.homegall-sec {
    margin-top: -33px;
    margin-bottom: 100px;
}
.homegall-sec .col-sm-4 {
    margin-bottom: 30px;
    vertical-align: top;
}
.homegallerybox {
    text-align: center;
}
.galleryimg {
    margin-bottom: 30px;
    width: 230px;
    height: 230px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}
.galleryimg img {
    border-radius: 100%;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    border: 5px solid #fff;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.galleryimg img:hover {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    border-color: #4D00C7;
}
.gallerlink {} .gallerydet h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #4D00C7;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;
}
.gallerydet h2:before {
    position: absolute;
    width: 105px;
    height: 2px;
    background-color: #b8b8b8;
    margin: auto;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: "";
}
.gallerycont {
    height: 97px;
    overflow: hidden;
    margin-bottom: 25px;
}
a.orangelink {
    color: #DB3C00;
}
a:hover.orangelink {
    color: #DB3C00;
    text-decoration: underline;
}
.researchboximg {
    width: 330px;
    height: 330px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}
.researchboximg img {
    border-radius: 100%;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    border: 5px solid #fff;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.researchboximg img:hover {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    border-color: #4D00C7;
}
.support-research-sec {
    margin-bottom: 120px;
}
.homerestext {
    margin-bottom: 20px;
}
h2.bluetitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #4D00C7;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
h2.bluetitle:before {
    position: absolute;
    max-width: 500px;
    width: 100%;
    height: 1px;
    background-color: #b8b8b8;
    left: 0px;
    bottom: 0px;
    content: "";
}
.homelatestnewssec {
    margin-bottom: 50px;
}
.newssec {
    padding-top: 20px;
}
.newssec .col-sm-3 {
    margin-bottom: 30px;
}
.homenewsbox {
    border-top: 1px solid #e8e4e4;
    -webkit-box-shadow: 1px 4px 1px 6px #e8e4e4;
    -moz-box-shadow: 1px 1px 4px 1px #e8e4e4;
    box-shadow: 1px 1px 4px 1px #e8e4e4;
    transition: all 0.5s ease-in-out 0s;
}
.homenewsbox:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}
.latestnewsimg {
    text-align: center;
    height: 230px;
    position: relative;
    transition: all 0.5s ease-in-out 0s;
    overflow: hidden;
}
.latestnewsimg img {
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    transition: all 0.5s ease-in-out 0s;
}
.latestnewsimg img:hover {
    opacity: 0.7;
}
.latestnewsdet {
    margin: 20px;
    height: 120px;
    overflow: hidden;
    line-height: normal;
}
.newslink {
    padding: 10px 20px 15px 20px;
}
.newslink .orangelink {
    font-size: 18px;
    display: block;
}
.newslink .orangelink i {
    float: right;
    font-size: 15px;
    padding-top: 4px;
}
.newslettergraybg {
    background-color: #f5f3f1;
    padding: 50px 0px;
    margin-bottom: 80px;
    text-align: center;
}
.newslettergraybg h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #333333;
    position: relative;
    margin-bottom: 35px;
}
.newslettergraybg .container {
    max-width: 1200px;
}
.joinnowtext {
    color: #4d00c7;
    margin-bottom: 25px;
}
.newsletterinput {
    position: relative;
    max-width: 675px;
    margin-left: auto;
    margin-right: auto;
}
.newsletterinput input {
    background-color: #fff;
    width: 100%;
    border: 1px solid #b1b1b1;
    padding: 15px 80px 15px 40px;
    border-radius: 0px;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.newsletterinput input[type="submit"] {
    position: absolute;
    width: auto;
    right: 0px;
    top: 0px;
    background-color: #4d00c7;
    color: #fff;
    font-weight: 700;
    border: none;
    z-index: 1;
    cursor: pointer;
    padding: 16px 20px;
    transition: all 0.5s ease-in-out 0s;
}
.newsletterinput input[type="submit"]:hover {
    background-color: #DB3C00;
}
.footerboxwidht {
    max-width: 420px;
}
.footeraddsec {} .footeraddsec .col-sm-4 {
    margin-bottom: 10px;
}
.footeraddsec h2 {
    font-weight: 500;
    color: #4D00C7;
    position: relative;
    margin-bottom: 20px;
    font-size: 22px;
}
.footeraddsec a {
    color: #333333;
    font-size: 20px;
}
.footeraddsec a:hover {
    color: #DB3C00;
    text-decoration: underline;
}

.footerLinks p {
    font-size: 20px;
}
.ftboxMB {
    margin-bottom: 30px;
}
.ftmap {
    float: right;
    text-align: right;
}

.ftmap iframe {
    width: 220px;
    height: 220px;
    border-radius: 100%;
    overflow: hidden;
}
.ftmap img {
    border-radius: 100%;
    /*width:204px; height:204px;*/
    max-width: 75%;
    margin: 15px 0px 0px 0px;
}
.rightlogo {
    text-align: right;
}
.footeraddsec ul {
    margin: 0px;
    padding: 0px;
}
.footeraddsec li {
    list-style-type: none;
    display: block;
    margin-bottom: 2px;
}
.footeraddsec li a {
    color: #333333;
}
.footeraddsec li a:hover {
    color: #DB3C00;
    text-decoration: underline;
}

.footerLinks {
    background: url(888faa41790e35902f9013315d722399.png) no-repeat 70% 100% #F5F3F1;
    padding-top: 60px;
    margin-top: -60px;
    background-size: 30%;
}

.footerSocialMedia {
    background-color: #575454;
    padding-top: 10px;
}

.footercopyright {
    padding: 10px 0px 20px 0px;
    overflow: hidden;
}
.footercopyright ul {
    margin: 0px;
    padding: 0px;
}
.footercopyright li {
    list-style-type: none;
    display: inline-block;
}
.followussection {
    float: left;
}
.followussection strong {
    font-size: 18px;
    color: #fff;
    margin-right: 25px;
    font-weight: 600;
}
.followussection ul {
    display: inline-block;
}
.followussection ul li {
    margin-left: 20px;
    font-weight: 500;
    font-size: 18px;
}
.followussection ul li a.twitter-color {
    color: #55acec;
}
.followussection ul li a.facebook-color {
    color: #3a5899;
}
.followussection ul li a.instagram-color {
    color: #0d5286;
}
.followussection ul li a.youtube-color {
    color: #ba0301;
}
.followussection ul li a.linkedin-color {
    color: #000000;
}
.followussection ul li a {
  color: #fff;
  font-size: 26px;
}
.followussection ul li i {
    margin-right: 4px;
    font-size: 26px;
}
.followussection ul li a:hover {
    color: #DB3C00 !important;
    text-decoration: none;
}
.footernav {
    /*float: right;*/
    line-height: normal;
}
.footernav li {
    color: #fff;
    padding-left: 5px;
    border-right: 1px solid #fff;
    padding-right: 9px;
    line-height: 20px;
}
.footernav li:last-child {
    padding-right: 0px;
    border: none;
}
.footernav li a {
    /*color: #4d00c7;*/
    color: #fff;
    font-size: 16px;
}
.footernav li a:hover {
    color: #DB3C00;
    text-decoration: underline;
}

.footerCredits {
    padding: 20px 0px;
    font-size: 14px;
}

.footerCredits p {
    line-height: 20px;
    /* padding: 20px 50px 0px 0px; */
    padding-top: 20px;
    padding-bottom: 8px;
}

@media only screen and (max-width: 769px) {
    .footerCredits p {
        padding-right: 0px;
    }
}

.footerCredits .footerLogo {
    width: 80%;
    max-width: auto;
    margin-bottom: 15px;
}

.footerCredits .footerLogo.medium {
    width: 50%;
}

.footerCredits .footerLogo.small {
    width: 30%;
}

.creditWrapper {
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.creditWrapper img {
    width: 90px;
    margin-right: 35px;
    /* float: left; */
    /* vertical-align: middle; */
}

.creditWrapper p {
    padding: 0;
}

.topheader {
    padding: 30px 0px 16px 0px;
    z-index: 999;
    position: relative;
}
.topsec {
    z-index: 9;
    position: relative;
}
.donate-dropdown .donate-dropdown-menu {
    position: absolute;
    top: 42px;
    right: 0;
    background-color: #f48222;
    transform: scaleY(0);
    transform-origin: top center;
    opacity: 0;
    transition: all 0.2s;
    box-shadow: none;
    min-width: 150px;
}

.donate-dropdown .donate-dropdown-menu .separator {
    border-top: 1px solid #c4c4c4;
}

.donate-dropdown:hover .donate-dropdown-menu {
    transform: scaleY(1);
    opacity: 1;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}
.donate-btn {
    display: block;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background: #dc3c00;
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    padding: 4px 16px;
    border: none;
    white-space: nowrap;
    transition: all .25s;
    border-radius: 2px;
}
.donate-btn:hover,
.donate-btn:focus {
    color: #fff;
    text-decoration: none;
    background: #4d00c7;
}
.donate-dropdown .donate-dropdown-menu a {
    background: #dc3c00;
    white-space: nowrap;
    display: block;
    font-size: 18px;
    color: #fff;
    text-transform: inherit;
    padding: 10px 15px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}
.donate-dropdown .donate-dropdown-menu a:hover {
    background-color: #4D00C7;
    text-decoration: none;
}
.donate-dropdown {
    display: inline-block;
    /*padding-top:3px;*/
    ;
}
.top-logo {
    position: absolute;
    left: 0px;
    z-index: 9;
}
/*.main-header-right {position:absolute; right:0px; z-index:1; top:-5px;}*/

.search-form-wrapper {
    position: absolute;
    top: 100%;
    margin-top: 30px;
    opacity: 0;
    visibility: hidden;
    right: 0;
    width: 400px;
    padding: 15px 0;
    background: #ffffff;
    text-align: center;
    border-top: 1px solid #dddddd;
    z-index: 12;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.10);
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
}
.search-form {
    max-width: 600px;
    margin: 0 auto;
}
.search-form-wrapper input {
    background: transparent;
    padding: 9px 15px;
    border: 1px solid #dddddd;
    width: 96%;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
}
/*.search-close{display:inline-block; margin-left:5px; vertical-align:middle; opacity:0.5; cursor:pointer;
transition: all 0.3s ease-in-out 0s; -moz-transition: all 0.3s ease-in-out 0s; -webkit-transition: all 0.3s ease-in-out 0s; -o-transition: all 0.3s ease-in-out 0s;}*/

.search-close:hover {
    opacity: 1;
}
.search-close img {
    max-width: 24px;
}
.search-form-wrapper.show-search {
    margin-top: 7px;
    opacity: 1;
    visibility: visible;
}
.search-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: top;
}
.header-search-icon {
    cursor: pointer;
    padding: 3px 10px 8px 10px;
    color: #4700c7;
    font-size: 25px;
    margin-right: 30px;
}
.header .wrapper {
    position: absolute;
    left: 0px;
    top: -11px;
    z-index: 99;
}
/*.wsmenuexpandermain {position:absolute; display:block;}*/

.wsmenu-list > li {
    display: inline-block;
}
.wsmenu-list {
    text-align: center;
}
.wsmenu {
    width: auto;
    max-width: inherit;
    /*margin: 0px 185px 0px 185px;*/
    background-color: transparent;
}
/*.wsmenu-list li:last-child {padding-right:170px;}*/

/*.wsmenu-list > li .megamenu:before {position:absolute; width:50%; left:-50%; top:0px; content:""; background-color:#6ec6d9; height:100%;}
.wsmenu-list > li .megamenu:after {position:absolute; width:50%; right:-50%; top:0px; content:""; background-color:#6ec6d9; height:100%;} */

.wsmenu-list li a {
    white-space: normal !important;
    font-family: 'Roboto', sans-serif;
}
.wsmenu-list li {
    white-space: normal !important;
    font-family: 'Roboto', sans-serif;
}
.megamenu ul {
    margin: 0px;
    padding: 0px;
    color: #4A4A4A;
}
.menusecbox {
    margin-bottom: 15px;
}
.wsmenu-list li .menusecbox h4 {
    font-size: 18px;
    font-weight: 400;
    color: #4A4A4A;
    margin-bottom: 10px;
}
.wsmenu-list li .menusecbox h4 a {} .wsmenu-list li .menusecbox h3 {
    font-size: 22px;
    font-weight: 400;
    color: #4A4A4A;
    margin-bottom: 10px;
}
.wsmenu-list li .menusecbox h3 a {} .findplace {
    font-size: 17px;
    color: #4A4A4A;
    font-weight: 400;
}
.smallogo .mobilebiglogo {
    display: inline-block;
}
.smallogo .mobilesmalllogo {
    display: none;
}
.sup-resdet {
    overflow: hidden;
}
.mainlogo {
    position: absolute;
    left: 0px;
    top: -11px;
}

.MainPTB {
    padding: 60px 0px 60px 0px;
}
.breadcrumb-sec {
    margin-top: 40px;
}
.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
}
.breadcrumb-sec .breadcrumb {
    background-color: transparent;
    padding: 0px 0px 0px 10px;
    border-radius: 0px;
    margin-bottom: 0px;
    font-size: 18px;
}
.breadcrumb-sec .breadcrumb-item.active {
    color: #333;
}
.innerpageleftrightpanel .col-md-8,
.innerpageleftrightpanel .col-md-4 {
    margin-bottom: 30px;
}
.innerpageleftrightpanel .col-md-9,
.innerpageleftrightpanel .col-md-3 {
    margin-bottom: 30px;
}
.innerpageleftrightpanel a {
    color: #DB3C00;
}
.innerpageleftrightpanel p {
    line-height: 32px;
}
.userprobg {
    background-color: #f5f3f1;
    padding: 35px 25px 25px 25px;
    overflow: hidden;
}
.rightpanelMB {
    margin-bottom: 35px;
}
.userproimg {
    width: 265px;
    height: 265px;
    overflow: hidden;
    margin: 0px auto 30px auto;
}
.userproimg img {
    border-radius: 100%;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    border: 5px solid #f5f3f1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.userproimg img:hover {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    border-color: #4D00C7;
}
.person-details {} .person-details h4 {
    color: #4700c7;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 400;
}
.person-details span {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}
.innerpagecontent h2 {
    font-size: 30px;
    color: #4D00C7;
    font-weight: 300;
    padding-top: 5px;
}
.innerpagecontent h3 {
    font-size: 24px;
    color: #4D00C7;
    font-weight: 400;
}
.useremailink {
    margin-bottom: 15px;
    line-height: normal;
    padding-top: 7px;
}
.grayboxright {
    background-color: #f5f3f1;
}
.bluetabbg {
    /*background: #C6C7C5; */
    /*background: #C6C7C5 url(../images/header-icons/garvan-icon-2.svg) no-repeat 6% 50%;*/
    /*background-size: 43px;*/
    padding: 10px 20px;
}
.bluetabbg h4 {
    /*color: #4700c7;*/
    color: #333333;
    margin: 0;
    font-size: 24px;
    margin-bottom: 0px;
    /*font-weight: 400;*/
    font-weight: bold;
    /*padding-left: 60px;*/
    padding: 0px;
    line-height: normal;
}
.boxpadd {
    padding: 25px;
}
.rightcatlist {} .rightcatlist ul {
    margin: 0px;
    padding: 0px;
}
.rightcatlist li {
    list-style-type: none;
    display: block;
    margin-bottom: 4px;
}
.rightcatlist li a {
    color: #DB3C00;
}
.rightcatlist li a:hover {
    color: #DB3C00;
    text-decoration: underline;
}
.internationalimg {
    text-align: center;
    margin-bottom: 25px;
}
.internationalimg:last-child {
    margin-bottom: 0px;
}
.titleMB {
    margin-bottom: 50px;
}
.titleMB:empty {
    display: none;
}
.titleupper {
    text-transform: uppercase;
}
.innerpagecon ul {
    margin-bottom: 20px;
}
.innerpagecon li {
    margin-bottom: 15px;
}
.visitingsection {
    padding: 15px 15px 0px 15px;
}
.visitingsection .col-sm-3 {
    margin-bottom: 25px;
}
.staffimg {
    margin-bottom: 15px;
    text-align: center;
}
.staffimg img {
    border-radius: 50%;
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.visitername {
    text-align: center;
}
.leftvermenu .collapse {
    display: block;
}
.leftvermenu {
    margin-top: -20px;
}
.panelcontent nav.flypanels-treemenu ul li div {
    font-size: 18px;
}
.breadcrumb-sec.leftnone .breadcrumb {
    padding-left: 0px;
}
.bannerbackbg {
    background: url(d0c614c93b2ab7dcbdd41911be252475.jpg) repeat-x top;
}

0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
}
100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
}
100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}
.lightbluebgtab {
    margin-top: 0px !important;
}
.lightbluebgtab .garvan-header-menu {
    background-color: #02D5D2;
}
.bannerbottomtext {
    bottom: 0px !important;
}
.staffname {
    text-align: center;
}
.sponsorspartnersec .owl-dots {
    display: none;
}
.sponsprspart {
    padding-top: 30px;
}
.sponsprspart .owl-nav button {
    position: absolute;
    top: 45%;
    text-indent: -99999px;
    outline: none;
}
.sponsprspart .owl-nav button:focus {
    outline: none;
}
.sponsprspart .owl-nav .owl-prev {
    left: -35px;
}
.sponsprspart .owl-nav .owl-next {
    right: -35px;
}
.sponsprspart .owl-nav .owl-prev span {
    background: url(3703f3b7fa316c6f7f5dea4e2691ed76.png) no-repeat;
    width: 13px;
    height: 23px;
    display: block;
}
.sponsprspart .owl-nav .owl-next span {
    background: url(b1f2d3809b695744f3d3f5122d77d343.png) no-repeat;
    width: 13px;
    height: 23px;
    display: block;
}
.sponsprspart.owl-theme .owl-nav[class*="owl-"]:hover {
    background: none;
}
.sponsprspart .owl-nav span:focus {
    outline: none;
}
.sponsprspart.owl-theme .owl-dots {
    margin-top: 30px;
}
.sponsprspart.owl-theme .owl-dots .owl-dot span {
    width: 13px;
    height: 13px;
    background-color: #ccc;
}
.sponsprspart.owl-theme .owl-dots .owl-dot:focus {
    outline: none;
}
.sponsprspart.owl-theme .owl-dots .owl-dot.active span,
.sponsprspart.owl-theme .owl-dots .owl-dot:hover span {
    background: #4D00C7;
}
.menubtn {
    background-color: #e9e7e5;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    color: #333333;
    position: relative;
    display: none;
    margin-top: -30px;
}
.menubtn i {
    float: right;
    padding: 5px 10px 0px 0px;
}
.menucurrent .fa-caret-down::before {
    content: "\F0D8";
}
.header-navigation {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px;
    /*height: 60px;
    line-height: 60px;
    background-color: #333;
    text-align: center;*/
    z-index: 100;
    transition: all 0.5s;
}
.scrollUp {
    transform: translateY(-110px);
}
.slideUp {
    -webkit-transform: translateY(-110px);
    transform: translateY(-110px);
    transition: transform .5s ease-out;
}
.slideDown {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: transform .5s ease-out;
}
.tsla-prevent-scroll {
    overflow: hidden;
}
.overflowbody {
    overflow: hidden;
}
.mainPT {
    padding-top: 88px;
}

/* BUTTON */
.garvan-btn-orange {
    border: 0;
    box-shadow: 1px 1px 1px #CCC;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 30px;
    margin-left: 0;
    height: auto;
    background-color: #D94C00;
    text-transform: uppercase;
    margin-top: 10px;
}

/* #22953 - Donation page top menu size */
.subsection-give-now .MainPTB {
    padding: 0;
}