
@media only screen and (min-width: 1201px) and (max-width: 3000px) {
    body {
        font-size: 22px;
    }

    .panelcontent nav.flypanels-treemenu ul li div {
        font-size: 22px;
    }

}

@media only screen and (min-width: 1441px) and (max-width: 1635px) {
    .container {
        max-width: 1410px;
    }

    .latestnewsimg {
        height: 200px;
    }

    .footernav li {
        font-size: 18px;
    }

    .followussection ul li {
        font-size: 16px;
    }

    .followussection {
        font-size: 18px;
    }

    .bannersection {
        height: 575px;
        background-size: 100%;
    }

    .bannerbackbg {
        background-size: 100% 638px;
    }

    .banner-pos-rel {
        height: 435px;
    }

}

@media only screen and (min-width: 1300px) and (max-width: 1440px) {
    .container {
        max-width: 1270px;
    }

    .latestnewsimg {
        height: 178px;
    }

    .latestnewsdet {
        height: 138px;
    }

    .footernav li {
        font-size: 18px;
    }

    .followussection ul li {
        font-size: 16px;
    }

    .followussection {
        font-size: 18px;
    }

    .bannersection {
        height: 545px;
        background-size: 100%;
    }

    .bannerbackbg {
        background-size: 100% 638px;
    }

    .banner-pos-rel {
        height: 412px;
    }

    .followussection ul li i {
        font-size: 16px;
    }

    .followussection strong {
        font-size: 16px;
    }

    .footernav li {
        font-size: 17px;
        line-height: 15px;
    }

    .homegall-sec {
        margin-top: -55px;
    }

    .bluetabbg h4 {
        font-size: 22px;
    }

}

@media only screen and (min-width: 1201px) and (max-width: 1299px) {
    .latestnewsimg {
        height: 158px;
    }

    .latestnewsdet {
        height: 150px;
        font-size: 18px;
    }

    .footernav li {
        font-size: 18px;
    }

    .followussection ul li {
        font-size: 16px;
    }

    .followussection {
        font-size: 18px;
        float: none;
        margin-bottom: 15px;
        /*text-align: center;*/
    }

    .bannersection {
        height: 480px;
        background-size: 100%;
    }

    .banner-pos-rel {
        height: 365px;
    }

    .gallerydet h2 {
        font-size: 28px;
    }

    h2.bluetitle {
        font-size: 28px;
    }

    .footernav {
        float: none;
        /*text-align: center;*/
    }

    .wsmenu {
        font-size: 15px;
    }

    .header-search-icon {
        margin-right: 10px;
    }

    .bluetabbg h4 {
        font-size: 22px;
    }


    .panelcontent nav.flypanels-treemenu ul li div {
        font-size: 18px;
    }

    #section-nav .nav-tabs > li > a {
        padding-left: 8px;
        padding-right: 8px;
    }

    #section-nav .nav-tabs > li > a::after {
        padding-left: 11px;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1201px) {
    #section-nav .nav-tabs > li > a {
        padding-left: 8px;
        padding-right: 8px;
    }

    #section-nav .nav-tabs > li > a::after {
        padding-left: 11px;
    }

}

@media only screen and (min-width: 1021px) and (max-width: 1199px) {
    #section-nav .nav-tabs > li > a {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 14px;
    }

    #section-nav .nav-tabs > li > a::after {
        padding-left: 8px;
    }

    #main-header .desktop-search-container .search {
        margin-right: 7px;
    }

}

@media only screen and (min-width: 320px) and (max-width: 1020px) {
    #section-nav .nav-tabs > li {
        padding-left: 10px;
    }

    #section-nav-wrapper {
        padding-bottom: 0px;
    }

    .mainPT {
        padding-top: 106px !important;
    }

    .mobilesubmenu {
        /*height:160px; overflow:auto;*/
    }

    #section-nav {
        height: 101vh;
        overflow: auto;
    }

    .mobilesubmenu .container {
        max-width: inherit;
    }

}



@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .latestnewsimg {
        height: 130px;
    }

    .wsmenu {
        font-size: 13px !important;
    }

    .wsmenu-list li a {
        white-space: normal !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .footernav li {
        font-size: 18px;
    }

    .followussection ul li {
        font-size: 16px;
    }

    .followussection {
        font-size: 18px;
        float: none;
        margin-bottom: 15px;
        text-align: center;
    }

    .footernav {
        float: none;
        text-align: center;
    }

    .footeraddsec h2 {
        font-size: 24px;
    }

    .gallerydet h2 {
        font-size: 24px;
    }

    h2.bluetitle {
        font-size: 24px;
    }

    .newslettergraybg h2 {
        font-size: 24px;
        margin-bottom: 25px;
    }

    .latestnewsdet {
        margin: 15px 10px;
        font-size: 16px;
    }

    .newslink .orangelink {
        font-size: 16px;
    }

    .newslink {
        padding-left: 10px;
        padding-right: 10px;
    }

    .newslettergraybg .container {
        max-width: 800px;
    }

    .bannersection {
        height: 409px;
        background-size: 100%;
    }

    .banner-pos-rel {
        height: 311px;
    }

    .researchboximg {
        width: 300px;
        height: 300px;
    }

    .header-search-icon {
        margin-right: 0px;
    }

    .support-research-sec {
        margin-bottom: 80px;
    }

    .homegall-sec {
        margin-bottom: 50px;
    }

    .gallerycont {
        height: 106px;
    }

    .userproimg {
        width: 240px;
        height: 240px;
    }

    .bluetabbg h4 {
        font-size: 20px;
    }

    .boxpadd {
        padding: 20px 15px;
    }

    /*.garvan-header-menu li {padding: 10px 5px; font-size:16px;}
*/
    .lightbluebgtab .garvan-header-menu li {
        padding: 10px 5px;
    }

    .MainPTB {
        padding: 30px 0px;
    }

    .breadcrumb-sec .breadcrumb {
        padding-left: 0px;
    }

    .bannerbackbg {
        background-size: 100% 470px;
    }

    .innerpageleftrightpanel .col-md-9 {
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        max-width: 70%;
    }

    .innerpageleftrightpanel .col-md-3 {
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
    }

    .clinicalgenosec .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .clinicalgenosec .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .sponsprspart .owl-nav .owl-prev {
        left: -20px;
    }

    .sponsprspart .owl-nav .owl-next {
        right: -20px;
    }

    .menubtn {
        display: block;
        margin-top: 0px;
    }

    .showmenulist {
        display: none;
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    body {
        padding-top: 0px !important;
    }

    .sticking {
        width: 100%;
        top: inherit !important;
        animation: fadeInDown 0s ease-in-out;
        -webkit-animation: fadeInDown 0s ease-in-out;
        position: relative !important;
        z-index: 9999;
    }

    .topheader .container {
        padding-left: 0px;
        padding-right: 0px;
        max-width: inherit;
    }

    .header .wrapper {
        display: none;
    }

    .topheader {
        padding-top: 0px;
    }

    .main-header-right {
        position: fixed;
        right: 15px;
        z-index: 1;
        top: 6px;
    }

    .search-form-wrapper.show-search {
        margin-top: 4px;
    }

    .wsmenu-list > li {
        background: none;
        background-color: #ff0000;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: 0px;
    }

    .wsmenu-list > li > a {
        display: block;
    }

    .latestnewsimg {
        height: 205px;
    }

    .wsmenu {
        font-size: 13px !important;
    }

    .wsmenu-list li a {
        white-space: normal !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .footernav li {
        font-size: 18px;
    }

    .followussection ul li {
        font-size: 16px;
    }

    .followussection {
        font-size: 18px;
        float: none;
        margin-bottom: 15px;
        text-align: center;
    }

    .footernav {
        float: none;
        text-align: center;
    }

    .footeraddsec h2 {
        font-size: 17px;
    }

    .gallerydet h2 {
        font-size: 19px;
        height: 70px;
        overflow: hidden;
    }

    h2.bluetitle {
        font-size: 19px;
    }

    .newslettergraybg h2 {
        font-size: 22px;
        margin-bottom: 25px;
    }

    .latestnewsdet {
        margin: 15px 10px;
        height: 80px;
        /*font-size:16px;*/
    }

    .newslink .orangelink {
        /*font-size:16px;*/
    }

    .newslink {
        padding-left: 10px;
        padding-right: 10px;
    }

    .newslettergraybg .container {
        max-width: 800px;
    }

    .bannersection {
        height: 340px;
        background-size: cover;
    }

    .banner-pos-rel {
        height: 258px;
        max-width: 428px;
    }

    .bannerposab h1 {
        font-size: 24px;
    }

    .newssec .col-sm-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .gallerycont {
        height: 135px;
    }

    .footeraddsec .col-sm-4 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .footeraddsec .col-sm-4:last-child {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .footeraddsec .col-sm-4:last-child .footerboxwidht {
        max-width: inherit;
    }

    .footeraddsec .col-sm-4:last-child .ftboxMB {
        float: left;
        width: 50%;
    }

    .megamenu {
        padding: 15px 0px !important;
    }

    .megamenu .col-sm-3 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .megamenu .col-sm-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .megamenu .col-sm-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .menusecbox h3 {
        font-size: 20px;
    }

    .wsmenu-list > li .megamenu:before {
        display: none;
    }

    .wsmenu-list > li .megamenu:after {
        display: none;
    }

    .wsmenu-list li a {
        white-space: normal !important;
    }

    .wsmenu-list li {
        white-space: normal !important;
    }

    .researchboximg {
        width: 270px;
        height: 270px;
    }

    .galleryimg {
        width: 210px;
        height: 210px;
    }

    .mainlogo {
        display: none;
    }

    .smallogo {
        text-align: left;
        padding-left: 60px;
    }

    .megamenu .container .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .support-research-sec {
        margin-bottom: 80px;
    }

    .homegall-sec {
        margin-bottom: 50px;
    }

    .gallerycont {
        height: 106px;
    }

    .innerpageleftrightpanel .col-md-8 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .innerpageleftrightpanel .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .innerpageleftrightpanel .col-md-9 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .innerpageleftrightpanel .col-md-3 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .MainPTB {
        padding: 30px 0px;
    }

    .breadcrumb-sec .breadcrumb {
        padding-left: 0px;
    }

    .menusecbox h3 a {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .sticky {
        position: relative !important;
        background-color: transparent !important;
    }

    .sticking {
        background-color: transparent;
        width: 100%;
        animation: fadeInDown 0s ease-in-out;
        -webkit-animation: fadeInDown 0s ease-in-out;
        position: relative !important;
        z-index: 9999;
    }

    .sticking.topheader {
        padding-top: inherit;
        padding-bottom: inherit;
    }

    .sticking .mainlogo {
        top: inherit;
    }

    .lightbluebgtab .garvan-header-menu li {
        padding: 10px 5px;
    }

    .userproimg {
        width: 240px;
        height: 240px;
        float: left;
    }

    .person-details {
        /*margin-left: 270px;*/
        padding-top: 15px;
    }

    .menubtn {
        display: block;
        margin-top: 0px;
    }

    .showmenulist {
        display: none;
    }

    .breadcrumb-sec {
        display: none;
    }

    .mrginleft {
        margin-left: 100px;
    }

    .wsmenu.menuopen {
        height: 100vh !important;
    }

    .overlapblackbg {
        height: 100vh !important;
    }

    .mainPT {
        padding-top: 58px;
    }


    .container { max-width: 100%; }
    #portal-column-two .group-details {
        padding: 0;
        margin: 0;
    }

    .person-portrait { width: 200px!important; height: 200px!important; }

}

@media only screen and (max-width: 767px) {
    body {
        padding-top: 0px !important;
    }

    .sticking {
        width: 100%;
        top: inherit !important;
        animation: fadeInDown 0s ease-in-out;
        -webkit-animation: fadeInDown 0s ease-in-out;
        position: relative !important;
        z-index: 9999;
    }

    .topheader .container {
        padding-left: 0px;
        padding-right: 0px;
        max-width: inherit;
    }

    .header .wrapper {
        display: none;
    }

    .topheader {
        padding-top: 0px;
    }

    .main-header-right {
        position: fixed;
        right: 15px;
        z-index: 1;
        top: 6px;
    }

    .search-form-wrapper.show-search {
        margin-top: 4px;
    }

    .wsmenu-list > li {
        background: none;
        background-color: #ff0000;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: 0px;
    }

    .wsmenu-list > li > a {
        display: block;
    }

    .latestnewsimg {
        height: 205px;
    }

    .wsmenu {
        font-size: 13px !important;
    }

    .wsmenu-list li a {
        white-space: normal !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .footernav li {
        font-size: 18px;
    }

    .followussection ul li {
        font-size: 16px;
    }

    .followussection {
        font-size: 18px;
        float: none;
        margin-bottom: 15px;
        text-align: center;
    }

    .footernav {
        float: none;
        text-align: center;
    }

    .footeraddsec h2 {
        font-size: 22px;
    }

    .gallerydet h2 {
        font-size: 22px;
    }

    h2.bluetitle {
        font-size: 22px;
    }

    .newslettergraybg h2 {
        font-size: 22px;
        margin-bottom: 25px;
    }

    .latestnewsdet {
        margin: 15px 10px;
        height: 80px;
        /*font-size:16px;*/
    }

    .newslink .orangelink {
        /*font-size:16px;*/
    }

    .newslink {
        padding-left: 10px;
        padding-right: 10px;
    }

    .newslettergraybg .container {
        max-width: 800px;
    }

    .bannersection {
        height: 340px;
        background-size: cover;
    }

    .banner-pos-rel {
        height: 258px;
        max-width: 300px;
    }

    .bannerposab h1 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .bannerbluetext {
        font-size: 17px;
    }

    .banndet-text {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .newssec .col-sm-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .gallerycont {
        height: 135px;
    }

    .footeraddsec .col-sm-4 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .footeraddsec .col-sm-4:last-child {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .footeraddsec .col-sm-4:last-child .footerboxwidht {
        max-width: inherit;
    }

    .footeraddsec .col-sm-4:last-child .ftboxMB {
        float: left;
        width: 50%;
    }

    .gallerycont {
        height: auto;
    }

    .followussection ul li {
        font-size: 14px;
    }

    .followussection ul li i {
        font-size: 16px;
    }

    .followussection {
        font-size: 16px;
    }

    .footernav li {
        font-size: 16px;
    }

    .support-research-sec {
        margin-bottom: 40px;
    }

    .newslettergraybg {
        margin-bottom: 40px;
    }

    .megamenu {
        padding: 15px 0px !important;
    }

    .megamenu .col-sm-3 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .menusecbox h3 {
        font-size: 20px;
    }

    .wsmenu-list > li .megamenu:before {
        display: none;
    }

    .wsmenu-list > li .megamenu:after {
        display: none;
    }

    .wsmenu-list li a {
        white-space: normal !important;
    }

    .wsmenu-list li {
        white-space: normal !important;
    }

    .smallogo {
        text-align: left;
        padding-left: 60px;
    }

    .researchboximg {
        text-align: center;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .header-search-icon {
        margin-right: 10px;
    }

    .mainlogo {
        display: none;
    }

    .megamenu .container .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .homegall-sec {
        margin-bottom: 50px;
    }

    /*.garvan-header-menu li {padding: 10px 5px; font-size:16px; display:block; border-bottom:1px solid #ccc;}
*/

    .lightbluebgtab .garvan-header-menu li {
        border-color: #00a9a7;
    }

    .MainPTB {
        padding: 40px 0px;
    }

    .breadcrumb-sec {
        /*margin-bottom:20px;*/
        display: none;
    }

    .bluetabbg h4 {
        font-size: 20px;
    }

    .innerpagecontent h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .visitingsection {
        padding: 0px;
    }

    .visitingsection .col-sm-3 {
        -ms-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }

    .menusecbox h3 a {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .megamenu .col-sm-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .megamenu .col-sm-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .sticky {
        position: relative !important;
        background-color: transparent !important;
    }

    .sticking {
        background-color: transparent;
        width: 100%;
        animation: fadeInDown 0s ease-in-out;
        -webkit-animation: fadeInDown 0s ease-in-out;
        position: relative !important;
        z-index: 9999;
    }

    .sticking.topheader {
        padding-top: inherit;
        padding-bottom: inherit;
    }

    .sticking .mainlogo {
        top: inherit;
    }

    .bannersection .container {
        max-width: inherit;
    }

    .leftlogo {
        text-align: center !important;
    }

    .rightlogo {
        text-align: center !important;
    }

    .userproimg {
        width: 170px;
        height: 170px;
        float: left;
    }

    .person-details {
        margin-left: 200px;
        padding-top: 15px;
    }

    .menubtn {
        display: block;
        margin-top: 0px;
    }

    .showmenulist {
        display: none;
    }

    .mrginleft {
        margin-left: 100px;
    }

    .wsmenu.menuopen {
        height: 100vh !important;
    }

    .overlapblackbg {
        height: 100vh !important;
    }

    .mainPT {
        padding-top: 58px;
    }

    #section-nav .nav-tabs > li {
        padding-left: 10px;
    }

    #section-nav-wrapper {
        padding-bottom: 0px;
    }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .homegall-sec .col-sm-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .latestnewsimg {
        height: 149px;
    }

    .latestnewsdet {
        height: 98px;
        font-size: 16px;
    }

    .newslink .orangelink {
        font-size: 16px;
    }

    .footeraddsec h2 {
        font-size: 20px;
    }

    .ftmap iframe {
        width: 240px;
        height: 240px;
    }

    .researchboximg {
        width: 195px;
        height: 195px;
    }

    .header-search-icon {
        margin-right: 20px;
    }

    .sponsprspart .owl-nav .owl-prev {
        left: -20px;
    }

    .sponsprspart .owl-nav .owl-next {
        right: -20px;
    }

    /* Move the left column to the bottom */
    .column-one-container { order: 1; }


}

@media only screen and (min-width: 480px) and (max-width: 575px) {
    .researchboximg {
        text-align: center;
        margin-bottom: 30px;
    }

    .newssec .col-sm-3 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .latestnewsdet {
        height: auto;
    }

    .homenewsbox {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }

    .footeraddsec .col-sm-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .footeraddsec .col-sm-4:last-child .ftboxMB {
        float: none;
        width: auto;
    }

    .rightlogo {
        text-align: left;
    }

    .partnerlogos .col-sm-6 {
        margin-bottom: 20px;
    }

    .footerboxwidht {
        max-width: inherit;
    }

    .search-form-wrapper {
        right: -100px;
    }

    .heightnone {
        height: 0px;
    }

    .bannersection {
        height: 282px;
    }

    .banner-pos-rel {
        height: 230px;
        max-width: 200px;
    }

    .bannerposab h1 {
        font-size: 14px;
        font-weight: 400;
    }

    .banndet-text {
        font-size: 13px;
    }

    .bannerbluetext {
        font-size: 14px;
    }

    .userproimg {
        width: 150px;
        height: 150px;
    }

    .person-details {
        margin-left: 180px;
        padding-top: 10px;
    }

    .sponsprspart .owl-nav .owl-prev {
        display: none;
    }

    .sponsprspart .owl-nav .owl-next {
        display: none;
    }

    /* Move the left column to the bottom */
    .column-one-container { order: 1; }

}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .researchboximg {
        text-align: center;
        margin-bottom: 30px;
    }

    .newssec .col-sm-3 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .latestnewsdet {
        height: auto;
    }

    .homenewsbox {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }

    .footeraddsec .col-sm-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .footeraddsec .col-sm-4:last-child .ftboxMB {
        float: none;
        width: auto;
    }

    .rightlogo {
        text-align: left;
    }

    .partnerlogos .col-sm-6 {
        margin-bottom: 20px;
    }

    .heightnone {
        height: 0px;
    }

    .latestnewsimg {
        height: 180px;
    }

    .homenewsbox {
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
    }

    .footerboxwidht {
        max-width: inherit;
    }

    .search-form-wrapper {
        right: -130px;
        width: 290px;
    }

    .smallogo .mobilebiglogo {
        display: none;
    }

    .smallogo .mobilesmalllogo {
        display: inline-block;
    }

    .researchboximg {
        width: 230px;
        height: 230px;
    }

    .header-search-icon {
        margin-right: 5px;
    }

    .bannerposab h1 {
        font-size: 21px;
        margin-bottom: 5px;
    }

    .userproimg {
        width: 240px;
        height: 240px;
    }

    .visitingsection .col-sm-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .wsmenu.menuopen {
        width: 240px !important;
    }

    .wsmenu .wsmenu-list > li {
        width: 240px;
    }

    .wsmenuexpandermain .menuopen {
        left: 400px !important;
    }

    .bannersection {
        height: 240px;
    }

    .banner-pos-rel {
        height: 195px;
        max-width: 200px;
    }

    .bannerposab h1 {
        font-size: 14px;
        font-weight: 400;
    }

    .banndet-text {
        font-size: 13px;
    }

    .bannerbluetext {
        font-size: 14px;
    }

    .userproimg {
        float: none;
    }

    .person-details {
        margin-left: 0px;
    }

    .sponsprspart .owl-nav .owl-prev {
        display: none;
    }

    .sponsprspart .owl-nav .owl-next {
        display: none;
    }

    .wsmenuexpandermain .menuopen {
        left: 240px !important;
    }

    .donate-btn {
        font-size: 18px;
    }

    .main-header-right {
        top: 25px !important;
    }

    /* Move the left column to the bottom */
    .column-one-container { order: 1; }

}

img {
    max-width: 100%;
    height: auto;
    width: auto\9;
    /* ie8 */
}
