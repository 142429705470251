/***************** HTML ELEMENTS ***************/

/*
This creates double scrollbars in Firefox, breaks the sticky header in any browser
and moves the scrollbar funder the sticky header in Chrome
*/
/*html, body {
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
}
*/
a {
    /*color: #DB3C00;*/
    color: #4D00C7;
    font-family: Roboto;
}

a:hover {
    color: #CD0028;
}

a.call-to-action {
    padding: 10px 60px 10px 0;
    background: url(376a1d34be01d73fed3f156b0c7729a1.svg) no-repeat 95% 50%;
    color: #DB3C00;
    font-family: Roboto Slab;
    font-weight: 300;
    display: inline-block;
    margin: 10px 0px;
}

.pull-right {
    float: right;
}

p {
    padding: 10px 0;
}

ul, ol {
    padding-bottom: 10px;
}

ul > li, ol > li {
    margin: 5px 0;
    line-height: 1.2;
}

#edit-zone ul > li, #edit-zone ol > li {
    margin: 0;
    line-height: 1.2;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    margin-left: 20px;
    font-size: 18px;
    border-left: 5px solid #eeeeee;
}

blockquote p {
    font-weight: 400;
    font-style: italic;
    color: #738ea9;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.25;
}

.innerpagecontent table {
    border: 1px solid #e5e5e5;
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
}

.innerpagecontent table > thead > tr > th,
.innerpagecontent table > tbody > tr > th,
.innerpagecontent table > tfoot > tr > th,
.innerpagecontent table > thead > tr > td,
.innerpagecontent table > tbody > tr > td,
.innerpagecontent table > tfoot > tr > td {
    border: 1px solid #e5e5e5;
    padding:10px;
}

.innerpagecontent table.listing {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.innerpagecontent .listing > tbody > tr:nth-child(odd) > td,
.innerpagecontent .listing > tbody > tr:nth-child(odd) > th {
    background-color: #f6f6f6;
}

table.invisible-grid > thead > tr > th,
table.invisible-grid > tbody > tr > th,
table.invisible-grid > tfoot > tr > th,
table.invisible-grid > thead > tr > td,
table.invisible-grid > tbody > tr > td,
table.invisible-grid > tfoot > tr > td,
table.invisible-grid {
    border: 0;
}


/*********** General ***********/
.image-right{
    float: right;
    margin: 0.8em 0.5em;
}

.image-left {
    float: left;
    margin: 0.5em 1em 0.5em 0;
}

.more-link {
    float: right;
    margin-top: -10px;
}

.more-link-left {
    color: #4D00C7;
    font-size: 20px;
}

.more-link-left:hover {
    text-decoration: none;
    color: #4D00C7
}

.more-link-left-wrapper {
    background-color: #fdfdfd;
    margin-bottom: 12px;
    padding: 5px 12px;
    text-align: left;
}

.more-link-left:hover > span {
    text-decoration: underline;
}

.more-link:after,
.more-link-left:after {
    content: ' ';
    background: url(376a1d34be01d73fed3f156b0c7729a1.svg) 50% 100% no-repeat;
    padding-left: 45px;
    font-size: 26px;
}

a:hover > .more-link-left {
    text-decoration: none !important;
}

a:hover .more-link-left:after {
    text-decoration: none !important;
}

.more-news {
    margin-top: -5px;
}

#portal-footer-wrapper h4 {
    font-weight: 400;
    color: #4D00C7;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #4D00C7;
}


/**********TOP SEARCH*********/
#expand-search {
    height: 100%
}

#expand-search img {
    max-width: 80%;
    margin-top: 10px;
}

/************** PORTLETS ****************/

.portlet-static-linkedin {
    margin-top: 30px;
}

/*Left nav*/

/* .navTree .thumb-icon {
    display: none;
} */

.navTreeCurrentNode {
    color: #DB3C00 !important;
    border-bottom-color: #DB3C00 !important;
    text-decoration: none;
}

.navTreeCurrentNode:after {
    color: #DB3C00 !important;
}

.portletNavigationTree {
    width: 100%;
}

.portletNavigationTree header {
    display: none;
}

.portletNavigationTree .navTree {
    list-style: none;
    padding-left: 24px;
}

.portletNavigationTree .navTreeItem {
    list-style: none;
}

.portletNavigationTree .navTreeItem a {
    color: #4D00C7;
    padding-top: 23px;
    padding-bottom: 8px;
    display: flex;
    padding-left: 0px;
    padding-right: 27px;
    width: 100%;
    border-bottom: 1px solid #4D00C7;
    font-size: 22px;
    justify-content: space-between;
    align-items: flex-start;
}

.portletNavigationTree .navTreeItem a:hover {
    text-decoration: none;
    color: #DB3C00 !important;
}

.portletNavigationTree a.navTreeFolderish:after {
    content: "\F105";
    color: #4D00C7;
    width: 35px;
    font-family: FontAwesome;
    font-size: 22px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    right: 0;
    text-align:right;
    float: right;
    padding-left: 42px;
}

.portletNavigationTree ul.navTreeLevel0 {
    padding-left: 0;
}

.navTree li {
    margin-bottom: 0;
}

@media only screen and (min-width:992px) and (max-width:1299px)
{
    .portletNavigationTree .navTreeItem a {font-size:18px;}

    #section-nav .nav-tabs > li > a {padding-left:8px; padding-right:8px;}
    #section-nav .nav-tabs > li > a::after {padding-left: 11px;}
}

@media only screen and (max-width:992px)
{
    .portletNavigationTree {
        width: 100%;
    }

    .portletNavigationTree .navTreeItem a {font-size:18px;}
}

.documentFirstHeading {
    font-size: 30px;
    font-family: 'Roboto Slab', serif;
    color: #4D00C7;
    font-weight: 300;
}

.innerpagecon h3 {
    font-size: 30px;
    color: #4D00C7;
    font-weight: 300;
}

.portletNavigationTree .portletContent {
    padding-top: 0;
}

.navTreeTopNode a {
    padding-top: 5px !important;
}

.navTree {
    margin-bottom: 0px !important;
}

.navTreeLevel0 > li:first-child > a {
    padding-top:0;
}

.portletNavigationTree {
    background: none !important;
    margin-top: 0 !important;
}

.breadcrumb-sec ol {
    background-color: transparent;
    padding: 0;
    border-radius: 0px;
    margin-bottom: 0px;
    font-size: 18px;
    display: flex;
    list-style: none;
}

.breadcrumb-sec ol a {
    text-decoration: none;
    color: #333;
    -webkit-transition: all 0.5s ease-in-out 0s;
}

.breadcrumb-sec ol a:hover {
    color: #DB3C00;
    text-decoration: underline;
}

.breadcrumb-sec ol #breadcrumbs-home:before {
    display:none;
}

.breadcrumb-sec ol li:before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: ">";
}

#portal-column-two .group-details {
    /*background-color: #f5f3f1;*/
    padding: 0px 25px 0px 25px;
    overflow: hidden;
    margin-top: 0px !important;
}

#portal-column-two .group-details .social .ai,
#portal-column-two .group-details .social .fa {
    font-size: 22px;
}

.person-portrait {
    width: 265px;
    height: 265px;
    overflow: hidden;
    /*margin: 0px 0px 30px 0px;*/
}

.person-portrait img:hover {
    transform: scale(0.9);
    border-color: #4D00C7;
}

.person-portrait img {
    border-radius: 100%;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    border: 5px solid #f5f3f1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}

.person-details h3 {
    color: #4700c7;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 400;
}

.person-details .fullname {
    font-weight: 500;
}

.diseases-portlet {
    margin-top: 37px
}

.disease-listing {
    padding: 25px;
    /*background-color: #f5f3f1;*/
    overflow: hidden;
}

.disease-listing a:after {
    content: "\F105" !important;
    font-family: FontAwesome;
    padding: 0px 0px 0px 10px;
}

.portlet {
    /*background-color: #f5f3f1;*/
    overflow: hidden;
    /*margin-top: 37px;*/
}

.portletHeader {
    /*color: #4700c7;*/
    color: #333333;
    font-weight: bold;
    font-size: 24px;
    margin-bottom:0px;
    /*font-weight: 400;*/
    /*background: url(../images/header-icons/garvan-icon-2.svg) no-repeat;*/
    /*background-position: 20px 50%;*/
    /*padding-left: 60px;*/
    line-height: normal;
    padding: 10px 20px;
    /*background-color: #08d5d2;*/
    /*background-color: #C6C7C5;*/
    /*padding: 15px 15px 15px 75px;*/
    /*background-size: 43px;*/
}

.portletNavigationTree .portletContent {
    padding: 0 !important;
}

.portletContent {
    padding: 25px;
}

.portletNews .portletItem {
    list-style-type: none;
    padding-bottom: 16px;
}

.portletNews ul {
    padding: 0;
}

/***************PERSON SEARCH ****************/
#search-results .person {
    padding-bottom: 70px;
}

#search-results .person .person-info {
    /*float: left;*/
    /*margin-left: 30px;*/
    overflow: hidden;
}

#search-results .person .person-info span.team:after {
    content: ', ';
}

#search-results .person .person-info span.team:last-child:after {
    content: '';
}

@media only screen and (max-width:500px) {
    #search-results .person .person-info {
        font-size: 14px;
    }
}

#search-results .person .portrait {
    float: left;
    margin-right: 20px;
}


#search-results-bar {
    margin-bottom: 30px;
}

#search-results .listingBar {
    margin-top: 80px;
}

.search-result-header {
    margin-top: 40px;
}
#search-results .person
#search-field #search-results .person .searchPage {
    width: 100%;
}

.faculty-page-description {
    margin: 20px 0px 40px;
}

.faculty-page-description p {
    margin-bottom: 12px;
}

.faculty-page-description p.bold {
    font-weight: 500;
}

/************ Lab Search *************/
.labSearchItem {
    border: 0px solid #f00;
    padding-bottom: 50px;
}

.labSearchItem .thumb {
    float: left;
    margin-right: 30px;
/*    border: 0px solid #f00;
    overflow: hidden;
    background: #E8E8E7;
    border-radius: 50%;
    overflow: hidden;
*/}

/*.labSearchItem .thumb img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}*/

.labSearchItem .labName {
    border: 0px solid #aaa;
    overflow: hidden;
    color: #4D00C7;
    font-weight: 400;
}

.labSearchItem .diseaseList a:after {
    content: ',';
}

.labSearchItem .diseaseList a:last-child:after {
    content: '';
}

.labSearchItem .diseaseList a {
    color: #333333;
}




/************ NEWS PAGE *************/
/*Filtering*/
.filters {
    /*float: left;
    margin-left: 45px;
    margin-bottom: 40px;*/
    float: left;
    margin-left: 10px;
    margin-bottom: 40px;
    min-width: 200px;
    margin-right: 50px;
}

.filters-label {
    float: left;
    padding-top: 7px;
    color: #DB3C00;;
    font-size: 16px;
}

.filter-keyword {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent;
    margin: 0;
    padding: 0 !important;
    font-size: 16px !important;
    color: #b1b1b1 !important;
    border-bottom: 1px solid #b1b1b1 !important;
    border-radius: 0 !important;
}

.filter-category {
    float: left;
    width: 220px !important;
    margin-right: 40px !important;
    /* border-radius: 0 !important; */
}

input[type="text"], input[type="number"], input[type="email"], input[type="password"], textarea, select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 20px 12px;
    font-size: 18px;
    line-height: 18px;
    color: #696969;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

select {
    padding: 11px 12px;
    height: auto;
    -webkit-appearance: none;
    background: #fff url(e292600da9b3030359c1c111450774ed.png) no-repeat;
    background-position: 100% center;
}

.filter-date {
    margin-left: 20px;
    width: 100px;
    /* border-radius: 0 !important; */
    float: left;
}

.filters-submit {
    float: right;
    width: 100px;
    background-color: #4700c7;
    color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 1px rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 2px;
    -webkit-user-select: none;
    margin-right: 40px;
}

.filters-submit:hover {
    background-color: #3800a0;
    color: #fff !important;
}

/*News Tiles*/

#content .search-item.tiled {
    /* padding-bottom: 20px;
    border-top: 1px solid #e8e4e4;
    -webkit-box-shadow: 1px 4px 1px 6px #e8e4e4;
    -moz-box-shadow: 1px 1px 4px 1px #e8e4e4;
    box-shadow: 1px 1px 4px 1px #e8e4e4;
    float: left;
    margin-bottom: 30px;
    margin-right: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; */

    padding-bottom: 20px;
    border-top: 1px solid #e8e4e4;
    float: left;
    margin-bottom: 30px;
    margin-right: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f5f3f1;
}

.search-item.tiled:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.search-item.tiled .search-entry-image {
    ￼float: left;
    ￼width: 100%;
    ￼margin: 0 auto;
}

.search-item.tiled .tileImage {
    background-position: center center;
    background-size: cover;
    height: 324px;
    width: auto;
    display: block;
}

.search-item.tiled .search-entry-body {
    /* float: left; */
    padding: 20px;
    flex-grow: 1;
}

#content .search-item.tiled .documentByLine {
    ￼color: #DB3C00;
}

#content .search-item.tiled h3 {
    line-height: 1.3em;
    font-weight: 300 !important;
}

#content .search-item.tiled h3 a {
    ￼color: #333 !important;
    ￼text-decoration: none;
}

.search-entry-description {
    font-weight: 300;
}

.search-item.tiled .search-readmore {
    display: block;
    width: 36px;
}

.search-item .tileFooter {
    align-self: flex-end;
    padding-right: 15px;
}


/********* NEWSLETTER and EVENTS *********/
#content .news-item.listed {
    padding-top: 30px;
    padding-bottom: 20px;
    border-top: 1px solid #efefef;
}

#content .event.listed:first-child, #content .news-item.listed:first-child {
    border-top: none;
}

#content .event.listed, #content .news-item.listed {
    margin: 10px 0 0;
    border-top: 1px solid #cccccc;
    padding-bottom: 20px;
}

#content .event.listed div.tileImageNoWidth, #content .news-item.listed div.tileImageNoWidth {
    float: right;
    margin-top: 10px;
}

#content .news-item.listed h3 {
￼    line-height: 1.3em;
￼    font-weight: 300 !important;
}

#content .event.listed h3, #content .news-item.listed h3 {
    font-size: 20px;
    font-style: normal;
    line-height: 1em;
}

#content .news-item.listed h3 a {
    color: #4D00C7 !important;
    text-decoration: none;
}

#content .news-item.listed .documentByLine {
    color: #DB3C00;
}

#content .event.listed .documentByLine, #content .news-item.listed .documentByLine {
    font-size: 18px;
    color: #DB3C00;
    font-weight: 400;
}

#content div.documentByLine {
    opacity: .9;
}

.news-item h5 {
    margin: 10px 0;
    font-size: 18px;
}

.news-item li {
    font-size: 18px;
    margin-bottom: 5px;
}


/*********PUBLICATIONS**********/

.portaltype-garvan-types-publication .field.abstract {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 30px;
}

.portaltype-garvan-types-publication .field.abstract p {
    margin-bottom: 40px;
}

/*Publication search*/
.searchPage {
    position: relative;
}

.searchPage #search-field, .portaltype-garvan-types-publication #search-field {
    background-color: #E8E8E7;
    padding: 20px;
    text-align: left;
}

.searchPage .searchButton, .portaltype-garvan-types-publication .searchButton {
    background-color: #4700c7;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
    padding: 12px 16px 12px 12px;
    width: 100px;
    font-size: 18px;
    border: 1px solid #4700c7;
    line-height: 16px;
    border-radius: 5px;
}

.svg-search-icon {
    fill: white;
    position: absolute;
    margin: 3px 0 0 8px;
}

.searchPage .searchButton i, .portaltype-garvan-types-publication .searchButton i {
    float: right;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search {
    background-position: -48px 0;
}

/*

#22593 - 404 error on glyphicons file

[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url(/++theme++images/glyphicons-halflings.png);
    background-position: 14px 14px;
    background-repeat: no-repeat;
    margin-top: 1px;
}

*/

/*publication page*/
.portaltype-garvan-types-publication .title {
    line-height: 1.2em;
    margin: 40px 0 30px;
}


/************* LAB PAGES *************/
#staff-profiles .staff {
    float: left;
}

#staff-profiles .staff:nth-child(4n-3) {
    margin-left: 0;
    clear: left;
    padding-bottom: 50px;
}

#staff-profiles img {
    width: 100%;
}

#staff-profiles {
    margin-top: 30px;
}

#staff-profiles .staff .portrait, .leader-details .portrait {
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.leader-details .portrait {
    float: left;
}

#staff-profiles .staff .portrait img, .leader-details .portrait img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
    object-fit: cover;
}

.research-group {
    margin-bottom: 40px;
}

.research-group.listed dt {
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 10px;
    font-size: 20px;
}

.sub-heading {
    /*font-family: SourceSansPro, Helvetica, Arial, sans-serif;*/
    font-size: 30px;
    font-weight: normal;
    line-height: 1em;
    margin-top: 5px;
    margin-top: 10px;
}

.research-group > dt > a {
    color: #4700c7;
}

.research-group.listed .leader-details strong {
    display: block;
    line-height: 1em;
}

.research-group.listed .leader-details .portrait {
    width: 80px;
    height: 80px;
    margin-right: 16px;
}

.research-group.listed .leader-details img {
    display: inline-block;
    vertical-align: top;
}

.research-group .leader-details .leader-title {
    padding-top: 13px;
}

.groups-heading {
    margin-top: 30px;
}


/***********SITE MAP*************/
#portal-sitemap .navTreeLevel2 > .navTreeItem {
    margin-top: 15px;
}

#portal-sitemap .navTreeLevel2 > .navTreeItem:first-child {
    margin-top: 0 !important;
}

#portal-sitemap > .navTreeItem {
    margin-top: 20px;
}

#portal-sitemap > .navTreeItem:first-child {
    margin-top: 0 !important;
}

#portal-sitemap {
    margin-top: 25px;
}

/*********** STUDENT NEWS ***********/
.student-news-wrapper .news-item .tileImage img {
    float: right;
    margin-top: 6px;
}

.student-news-wrapper .news-item .tileHeadline {
    line-height: 1.3em;
    font-weight: 300 !important;
    font-size: 23px;
    font-style: normal;
}

.student-news-wrapper .news-item > div {
    font-size: 18px;
}

.student-news-wrapper .news-item .documentByLine {
    font-size: 18px;
}

.student-news-wrapper .news-item .tileFooter {
    margin-top: 30px;
    font-size: 18px;
    margin-bottom: 0;
    padding-right: 15px;
}

.student-news-wrapper .news-item .tileFooter a {
    color: #4D00C7 !important;
}

.student-news-wrapper .news-item .tileHeadline,
.student-news-wrapper .news-item .documentByLine,
.student-news-wrapper .news-item > div {
    padding-right: 205px;
}

/********** STUDENTS PAGE *********/

.portletStaticText h4 {
    font-weight:500;
    font-size: 17px;
}

.portletStaticText h2 {
    margin-top: 30px;
}

.portletStaticText h3 {
    font-weight: 400;
}

.portlet-static-unsw {
    margin-top: 31px;
}

/********** BONE FRACTURE RISK *********/
.documentDescription {
    font-size: 28px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 34px;
    margin-bottom: 15px;
    color: #003263;
}

body.portaltype-garvan-types-person .documentDescription {
    display: none !important;

}

.field.conjoint {
    margin-bottom: 25px;
}

.callout {
    background: #eee;
    padding: 1em;
    border-left: 1em solid #ccc;
    clear: both;
    margin-bottom: 15px;
}

.call-to-action {
    background-color: #DB3C00;
    color: #ffffff;
    color: #CD0028;
    font-size: 22px;
    font-weight: bold;
    margin: 40px 0px;
    overflow: hidden;
    padding: 25px 26px;
    position: relative;
}

.call-to-action .call-to-action-link {
    background-color: #ffffff;
    color: #DB3C00;
    float: right;
}

.call-to-action-link {
    background-color: #DB3C00;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    padding: 16px 14px;
    white-space: nowrap;
}

/******** INDIVIDUAL search PAGES ********/
#news-image {
    float: right;
    /*width: min-content;*/
    padding: 10px;
    max-width: 50%;
}

#news-image img {
    margin-bottom: 10px;
    max-width: 100%;
}

.subsection-news #content-core > h5 {
    font-size: 18px;
    font-weight: 300;
}

/******** SEARCH LISTING PAGES (news, confirgurable search, etc.) ********/
/*Tile view*/
#news-categories {
    border: 0px solid #f00;
    margin-right: 20px;
    margin-bottom: 50px !important;
}

.filters-label {
    margin-right: 10px;
}

.filter-keyword,
.filter-group,
.filter-month,
.filter-year {
    margin: 0px 5px 0px 0px !important;
    border-radius: 2px !important;
}

.filters-submit {
    float: right !important;
    margin-right: 0px;
}

@media only screen and (max-width: 1200px) {
    .filters-submit {
        float: none !important;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 992px) {
    .filter-keyword,
    .filter-group {
        width: 165px !important;
    }

    .filters-label {
        margin-right: 10px;
    }

    .filter-date {
        margin: 0px 5px 0px 0px;
    }
}

@media only screen and (max-width: 768px) {
    .filters-label {
        width: 100px;
        margin-right: 0px;
    }

    .filter-keyword,
    .filter-group {
        width: 220px !important;
    }

    .filter-group {
        margin: 5px 0px 5px 100px !important;
    }
}

@media only screen and (max-width: 768px) {
    .filter-group {
        margin: 5px 0px 5px 100px;
    }

    .filters-submit {
        margin-left: 100px;
    }
}

@media only screen and (max-width: 425px) {
    .filter-keyword,
    .filter-group,
    .filter-month,
    .filter-year,
    .filters-submit {
        width: 100% !important;
        display: block !important;
        margin: 5px 0px 0px 0px !important;
    }

    .search-result-list {
        display: block !important;
    }

    .contentIcon {
        margin: 20px auto;
        width: 70% !important;
        padding-top: 70%;
    }
}

.news-categories {
    float: left;
}

.search-items-wrapper {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.search-entry-image {
    padding-right: 0 !important;
}

.search-items-wrapper .documentByLine {
    padding-right: 0 !important;
    color: #DB3C00;
}

.search-items-wrapper .tileHeadline {
    margin-top: 13px;
}

.search-items-wrapper .tileHeadline {
    padding-right: 0 !important;
}

.listingBar a:hover {
    background: rgba(219, 58, 0, 1);
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
}

@media (min-width: 1300px) {
    .search-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 23%;
    }
}

@media (max-width: 1300px) {
    .search-item {
        max-width: 31%;
    }
}

@media (max-width: 995px) {
    .search-item {
        max-width: 47%;
    }
}

@media (max-width: 767px) {
    .search-item {
        max-width: 100%;
    }

    .search-result-list .title-and-date {
        display: block !important;
    }

    #content div.documentByLine {
        float: none;
    }

    .search-result-list .description-and-arrow {
        display: block !important;
    }

    .search-result-list .news-readmore {
        float: right;
    }
}

#top-filters {
    float: left;
}

#bottom-filters {
    float: left;
    min-width: 280px;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    #top-filters .filter-category {
        width: 90px !important;
    }
}

#content div.documentByLine {
    opacity: .5;
}

/*Pagenation*/
.listingBar {
    text-align: center;
    margin-top: 26px;
    width: 100%;
    display: inline-block;
}
.listingBar .next {
    margin-right: 6px;
}

.listingBar a {
    background: #fff;
    border: 1px solid #b5b5b5;
    color: #848484;
    padding: 10px;
}

.listingBar .page.current {
    background: #DB3C00;
    padding: 11px;
    color: #ffffff;
    font-weight: bold;
}

.listingBar .page.current, .listingBar .page a {
    width: 14px;
}



/******** NEWSLETTER PORTLET *********/
.newsletter-portlet {
    margin-top: 28px;
}

.newsletter-portlet > h3 {
    margin-bottom: 23px;
}

.newsletter-portlet .news-item-title {
    color: #4D00C7;
    font-size: 18px;
    font-weight: bold;
}

.newsletter-portlet .news-item-byline {
    color: rgb(127, 66, 224);
    margin-bottom: 15px;
    font-size: 16px;
}

.newsletter-portlet .portletStaticText h5 {
    font-size: 14px;
    font-weight: 400;
}

.newsletter-portlet .portletStaticText a {
    color: #4D00C7;
}

.newsletter-portlet .portletStaticText a:hover {
    text-decoration: underline;
}

/*********** NEWS LISTING PORTLET ***********/
.jobs-listing {
    overflow: visible !important;
}

/***********VIRTUAL TOURS (custom HTML, client copied many old portlets into one raw html portlet***********/
.portletItem {
    padding: 15px
}

/************FRONTPAGE NEWS ROW**********/
#newsevent-portlet h2 {
    color: #333333;
}

.news-row .item {
    height: 100%;
}

.frontpage-news-item {
    height: 450px;
    border-top: 1px solid #e8e4e4;
    -webkit-box-shadow: 1px 4px 1px 6px #e8e4e4;
    -moz-box-shadow: 1px 1px 4px 1px #e8e4e4;
    box-shadow: 1px 1px 4px 1px #e8e4e4;
    transition: all 0.5s;
}

.frontpage-news-item:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.frontpage-news-item .image-wrapper img {
    transition: all 0.5s;
    width: 100%;
}

.frontpage-news-item .image-wrapper img:hover {
    opacity: 0.9;
}

.frontpage-news-item a {
    text-decoration: none;
}

.news-title {
    padding: 12px;
    padding-bottom: 6px;
    color: #333;
    font-size: 20px;
    font-weight: 300;
    height: 150px;
    overflow: hidden;
}

.frontpage-news-item .details {
    color: #DB3C00;
    padding: 18px 12px;
    width: 100%;
    font-size: 16px;
}

.details .right-arrow {
    height: 13px;
    margin-bottom: 3px;
    float: right;
    margin-top: 4px;
    margin-right: 36px;
}

.more-news {
    margin-top: 24px;
    width: 161px;
    color: #DB3C00;
}

#news-container {
    padding-bottom: 123px;
    margin-top: 123px;
}

/*remove it on frontpage*/
.section-frontpage-new #news-container {
    /* display: none; */
    margin-top: 0;
}


/******** NEWS ROW ALL PAGES ********/
#newsevent-viewlet {
}

/* #22932 - Add horizontal padding to new viewless on mobile devices */
@media only screen and (max-width: 576px) {
    #newsevent-viewlet {
        padding: 0 15px;
    }
}

#newsevent-viewlet h2 {
    color: #333333;
}

/***********************************************************************************************************/
/****************************************************BLOCKS*************************************************/
/***********************************************************************************************************/

.portaltype-block-page .layout-row-wrapper .row {
    display: block;
}

.portaltype-block-page .container-fluid {
    padding: 0;
}

.portaltype-block-page .layout-portlet {
    /*display: flex;*/
    align-items: baseline;
}


/********ACCORDION BLOCK*******/
/*@media only screen and (min-width: 1150px) {
    .accordion {
        width: 100%;
    }
}

@media only screen and (max-width: 1150px) {
    .accordion {
        width: 75%;
    }
}

@media only screen and (max-width: 800px) {
    .accordion {
        width: 90%;
    }
}*/

.accordion {
    width: 100%;
}

.accordion .card-header h5 {
    margin: 0;
}

.card {
    margin-bottom: 10px;
    border: none;
    background: none;
}

.card-header {
    background-color: #dddddd70;
}

.card .btn {
    color: #333;
    font-size: 21px;
    /*font-weight: 600;*/
}

.collapse {
    background-color: white;
}

.card-body {
    font-size: 17px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.card .btn {
    text-align: left;
    word-wrap: normal;
    white-space: normal;
}

.card .btn:before {
    content: "+ ";
}

.card .btn[aria-expanded="true"]:before {
    content: "- ";
}


/******************* TITLE ROW BLOCK *****************/
.portaltype-block-page .title-row-wrapper {
    padding-top: 81px;
    padding-bottom: 81px;
    display: inline-block;
    /* background-image: url(http://garvan.local/research/diseases/cancer-prostate/prostate-cancer/image/@@download/image); */
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.portaltype-block-page .title-row-wrapper .header-title {
    font-size: 3.5em;
    font-family: Roboto Slab;
    font-weight: 300;
    color: #4D00C7;
}

.portaltype-block-page .layout-col-wrapper {
    /* background-image: url(http://garvan.local/research/diseases/cancer-prostate/prostate-cancer/image/@@download/image); */
    background-repeat: no-repeat;
    background-size: cover;
}

.portaltype-block-page .title-row-wrapper .cta-btn {
    margin-top: 30px;
}

.cta-btn {
    /* margin-top: 30px;  NOT USED */
}

.cta-btn a {
    /* color: white !important; NOT USED */
}

.portaltype-block-page .title-row-wrapper .cta-btn a:link {
    height: 40px;
    line-height: 40px;
    width: 160px;
    border: none;
    white-space: nowrap;
    text-align: center;
    padding: 13px 35px 13px 0px;
    font-size: 18px;
    line-height: 1.25;
    font-weight: 300;
    text-decoration: none;
}

.title-row-bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

/*************CALL TO ACTION BLOCKS*************/
.portaltype-block-page .cta-link {
    color: #DB3C00;
}

.portaltype-block-page .header-desc {
    font-size: 24px;
    line-height: 1.25;
    font-weight: 300;
    margin: 24px 0 0;
    max-width: 550px;
}

/********Call to action (left) ********/
.call-to-action-left .col {
    float: left;
    padding: 0;
}

.call-to-action-left .cta-body {
    padding-right: 30px;
}

.call-to-action-left .thumb {
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
    padding-top: 50%;
    border-radius: 50%;
    background-size: cover;
}

/********Call to action (right) ********/
.call-to-action-right .bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.call-to-action-right .text-content {
    float:right;
}

.call-to-action-right .thumb {
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
    padding-top: 50%;
    border-radius: 50%;
    background-size: cover;
    margin: 0px auto;
}


/******** Image block ********/
.image-block {
    background-repeat: no-repeat;
    background-position: center center;
    width: 80%;
    padding-top: 80%;
    border-radius: 50%;
    background-size: cover;
    margin: 0px auto;
}


/************ KEY STATISTICS BLOCK ***********/
.key-statistics-slide {
    /*background-color: #003263;*/
    background: #F5F3F1;
    padding: 30px;
    min-height: 315px;
}

#blockpage-wrapper .slider-row {
    display: flex !important;
    overflow-x: hidden;
    flex-wrap: nowrap;
}

#blockpage-wrapper .cta-slider-row {
    display: flex !important;
    overflow-x: hidden;
    flex-wrap: nowrap;
}

.cta-slider-row > div .col-gutter {
    display: flex;
    justify-content: center;
}

.cta-slider-row {
    padding: 40px 0;
}

.template-layout-manager .slider-row {
    overflow-x: none;
}

.key-statistics-body {
    /*color: #fff;*/
    color: #4D00C7;
    font-size: 20px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 20px;
}

.key-statistics-header {
    font-size: 60px !important;
    margin: 0 !important;
    /*color: #00b0b2 !important;*/
    color: #4D00C7;
    letter-spacing: -.05em;
    line-height: 1;
}

.key-statistics-image {
    padding: 10px 0;
}

.slider-control-left {
    width: 20px;
    display: block;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    cursor: pointer;
}

.slider-control-right {
    width: 20px;
    display: block;
    float: right;
    cursor: pointer;
}

.slider-control-left i,
.slider-control-right i {
    color: #4D00C7;
}

/*********DISEASE SECTION BLOCK***********/
.disease-sections .col-gutter .layout-portlet:nth-child(even) {
    background-color: #f7f7f7;
}

.disease-section {
    padding-bottom: 30px;
}

.disease-sections .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    height: 360px;
}

.disease-section-left {
    margin-top: 30px;
}

.disease-section-left .thumb {
    position: relative;
    width: 80%;
    padding-bottom: 56.25%;
    margin: 0px auto;
}

.disease-section-image {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 50%;
    padding-top: 100%;
}

.disease-section .col {
    float: left;
}

.disease-section-header {
    color: #666 !important;
    padding: 24px 0 13px 0;
}

@media screen and (min-width: 768px) {
    .disease-section-links ul {
        -moz-column-count: 2;
        -moz-column-gap: 20px;
        -moz-column-fill: auto;
        -webkit-column-count: 2;
        -webkit-column-gap: 20px;
        -webkit-column-fill: auto;
        column-count: 2;
        column-gap: 20px;
        column-fill: auto;
        list-style-position: inside;
        padding-left: 0px;
    }
}

.disease-section-links li {
    padding: 0 24px 0 0;
    line-height: 1.25;
    padding-bottom: 4px;
    font-size: 18px;
    line-height: 1.25;
    /* color: #DB3C00; */
}

.disease-section-links li a {
/*    font-size: 18px;
    line-height: 1.25;
    color: #DB3C00;
    text-decoration: none; */
}

.disease-section-links ul {
    list-style: none;
}

.disease-section-links .link-arrow {
    display: none;
}

.disease-section-body {
    font-size: 18px;
}

/********* FILE DOWNLOAD BLOCK ********/
.file-download-block {
    padding-bottom: 20px;
}

.file-download-block .information {
    display: table-cell;
    padding: 0px 0px 10px 30px;
    vertical-align: middle;
}

.information-title {
    font-size: 24px;
    /*color: #DB3C00;*/
    color: #4d00c7;
    font-weight: 300;
}

.information-description {
    font-size: 18px;
}

.information-link {
    font-size: 18px;
    color: #DB3C00;
}

/* Make title of nav separate from other items*/
.subsection-diseases .navTreeTopNode a, .section-our-work .portlet.portletNavigationTree .navTreeLevel0>.navTreeItem>a.navTreeItemInPath, .section-our-work .navTreeTopNode a {
    border-top: 0 !important;
    border-bottom: 0 !important;
    color: #DB3C00 !important;
    font-size: 32px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 24px;
    /*font-family: EBGaramond,serif;*/
    line-height: 1.25 !important;
    word-wrap: break-word;
}


/********* RICH TEXT BLOCK *********/
[data-icon]:before {
    font-family: 'icomoon';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

.rich-text-block {
    width: 100%;
}

/******* PROMO BLOCK ********/

.col-tile .call-to-action {
    width: 100%;
    overflow-wrap: break-word;
}

.size-restrictor {
    /* margin: 0 auto; */
    max-width: 237px;
}

.col-tile-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    /* https://dev.to/tchaflich/a-width-responsive-perfect-square-in-pure-css-3dao */
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    display: inline-block;

    margin-bottom: 20px;
}

.promo-tile-title {
    display: 'block';
    font-size: 24px;
    color: #4D00C7;
    font-weight: 400;
}

@media only screen and (max-width: 1280px) {
    /* .col-tile-image {
        width: 175px;
        padding-bottom: 175px;
    } */
    .call-to-action {
        padding: 10px 40px 10px 0;
        margin-right: -20px;
    }
}

@media only screen and (max-width: 960px) {
    /* .col-tile-image {
        width: 130px;
        padding-bottom: 130px;
    } */

    /*
        #22900 - vertically align text and the portrait in promo tile block on narrow screens
        (see image 0009 on that task)
    */
    .col-tile {
        align-items: center;
        margin-bottom: 20px;
    }

    .col-tile-content h3:empty,
    .col-tile-content a:empty,
    .col-tile-content .col-text-content:empty {
        display: none;
    }

    a.call-to-action {
        padding: 0;
        background-image: none;
        font-size: 18px;
    }
}

@media only screen and (max-width: 576px) {
    .col-tile-image {
        margin-bottom: 0;
    }
}


@media only screen and (max-width: 426px) {
    /* .col-tile-image {
        width: 80px;
        padding-bottom: 80px;
    } */

    /*
        #22900 - vertically align text and the portrait in promo tile block on narrow screens
        (see image 0009 on that task)
    */
    .col-tile {
        align-items: center;
    }

    .col-tile-content h3:empty {
        display: none;
    }

    .col-tile-content .col-text-content:empty {
        display: none;
    }

    /*
        #22900 - remove extra padding
        (see image 0008 on that task)
    */
    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .footerLinks {
        padding-left: 15px;
        padding-right: 15px;
    }

    /*
        #22900 - More text Block formatting
        (see image 0007 on that task)
    */
    .moretext-caption {
        margin: 0;
        text-align: center;
    }

    .moretext-wrapper h2 {
        display: flex;
        align-items: center;
    }

    .moretext-wrapper h2 a {
        margin-left: 15px;
    }
}

.cta-btn a:link {
    /*background: #DB3C00;*/
    height: 40px;
    line-height: 40px;
    width: 160px;
    border: none;
    white-space: nowrap;
    text-align: center;
    padding: 13px 25px 13px 0px;
    color: #DB3C00 !important;
    font-size: 18px;
    line-height: 1.25;
    font-weight: 500;
    text-decoration: none;
    background: url(376a1d34be01d73fed3f156b0c7729a1.svg) no-repeat 100% 50%;
}

.cta-btn a:link img {
   /*  display: none; NOT USED */
}

.col-tile-header {
    color: #4D00C7 !important;
    /*font-size: 20px !important;*/
    font-weight: normal;
    padding-top: 15px;
}

/********MORE TEXT BLOCK *********/

.moretext-wrapper {
    background-color: #F5F3F1;
    margin-bottom: 50px;
    width: 100%;
    overflow: auto;
}

.moretext-wrapper .moretext-image {
    float: right;
    margin: 0px 30px 20px 30px;
    padding: 0px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.moretext-wrapper .intro-text {
    margin: 30px;
    padding-bottom: 1.5em;
}

.moretext-wrapper .more-text {
    margin: 30px;
}

.moretext-wrapper .moretext-more {
    padding: 0px 30px 30px 30px;
    font-size: 18px;
    color: #DB3C00;
    border-bottom: 1px solid #DB3C00;
    cursor: pointer;
    font-weight: 400;
}

.moretext-wrapper blockquote, .moretext-wrapper blockquote p {
    color: #4D00C7;
    font-size: 22px;
    line-height: 1.25;
    font-weight: 300;
    font-style: italic;
    text-indent: 40px;
}

.moretext-wrapper blockquote {
    padding: 0px;
    border-left: none;
    margin-left: 0px;
}

.moretext-wrapper h2 {
    padding: 30px 30px 0px 30px;
    display: flex;
    align-items: center;
}

.moretext-wrapper h2 a {
    padding-left: 10px;
}

.moretext-caption {
    font-size: 16px;
    color: #000000;
    text-align: center;
    /* margin-right: 24px; */
}


/*******TABS BLOCK*******/
.conditional-tabs-horizontal.resp-tab-item {
    width: 33.3%;
    margin: 0;
    border: none !important;
    /*background-color: #c1c1c1 !important;*/
    /*color: #fff;*/
    font-size: 20px;
    line-height: 1.25;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.conditional-tabs-horizontal.resp-tab-active {
    background-color: #c6c7c5 !important;
}

@media only screen and (max-width: 768px) {
    .conditional-tabs-horizontal.resp-tab-active {
        background-color: #dc3c00 !important;
        color: #fff;
    }
}

.resp-tabs-list li {
    font-weight: 600;
    font-size: 13px;
    display: inline-block;
    padding: 16px 15px;
    margin: 0 4px 0 0;
    list-style: none;
    cursor: pointer;
    float: left;
}

ul.resp-tabs-list, p {
    margin: 0px;
    padding: 0 0 10px 0;
}

.resp-tabs-container {
    padding: 0px;
    background-color: #fff;
    clear: left;
}

li.conditional-tabs-horizontal.resp-tab-item:hover {
    background-color: #DB3C00 !important;
    color: #fff;
}

.conditional-tabs-horizontal.resp-tab-content {
    border: none !important;
    padding: 30px;
}

figcaption, .discreet {
    color: #009cbf !important;
}

/******** FRONTPAGE TILE BLOCK ********/
.frontpage-block {
    width: 100%;
    display: block;
    height: 215px;
    background-size: 100%;
}

.frontpage-block-text {
    background-color: rgba(26,35,74,.53)!important;
}

.frontpage-block-text a {
    color: #fff!important;
}

.frontpage-block-text .right-arrow {
    height: 14px;
    margin-top: 3px;
    float: right;
}

.frontpage-block-text {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    top: 40%;
    position: absolute;
    margin-top: -30px;
    left: 15%;
    text-rendering: optimizelegibility;
    font-size: 18px;
    font-weight: 300;
    width: 280px;
}


/********TIMELINE ITEM BLOCK********/
.timeline-item {
    width: 100%;
    margin-bottom: 70px;
}

.timeline-img {
    width: 30px;
    height: 30px;
    background: #4D00C7;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px;
}

.timeline .col-gutter .layout-portlet:nth-child(even) .timeline-content {
    float: right;
    padding: 40px 30px 10px 30px;
}

.timeline-content {
    position: relative;
    width: 45%;
    padding: 10px 30px;
    border-radius: 4px;
    background: #f5f5f5;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
}

.timeline-card {
    padding: 0 !important;
}

.timeline-img-header {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
}

.timeline-content .date {
    background: #222222;
    display: inline-block;
    color: #FFFFFF;
    padding: 15px;
    position: absolute;
    top: 0;
    right: 0;
}

.timeline-card h2 {
    padding: 0 20px;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 40px;
    color: #003263;
    text-rendering: optimizelegibility;
}

.timeline-card p {
    padding: 0 20px;
}

.timeline a.btn-more {
    background: #DB3C00;
    color: #FFFFFF;
    padding: 12px 28px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 30px;
    display: inline-block;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.6);
    text-decoration: none;
    letter-spacing: 1px;
}

.timeline-card a {
    margin-left: 20px;
}

.timeline a.btn-more:hover, .timeline a.btn-more:active, .timeline a.btn-more:focus {
    background: #4D00C7;
    color: #FFFFFF;
    text-decoration: none;
}

.timeline-item::after {
    content: '';
    display: block;
    clear: both;
}

.timeline-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    right: -15px;
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #f5f5f5;
}

.timeline::before {
    content: '';
    background: #cccccc;
    width: 5px;
    height: 95%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

/******* FRONTPAGE FULL WIDTH STILL *******/
.frontpage-banner {
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-size: cover;
    background-repeat: no-repeat;
}

.frontpage-overlay {
    background: rgba(43,66,111,.29)!important;
    width: 100%!important;
    height: 100%!important;
    top: 0!important;
    left: 0!important;
    right: 0!important;
    bottom: 0!important;
    transform: none!important;
    position: absolute;
}

.frontpage-title-wrapper {
    padding: 80px 100px;
    display: flex;
    width: 85%;
}

.frontpage-title {
    color: white !important;
    font-weight: 400;
    font-size: 34px;
    font-family: Roboto Slab;
}

.frontpage-title-desc {
    color: white !important;
    font-weight: 300 !important;
    line-height: 1.2em;
}

.frontpage-title-cta {
    font-size: 22px;
    font-weight: 400;
    color: #DB3C00;
    margin-top: 20px;
    display: block;
    pointer-events: all;
    cursor: pointer;
}

.frontpage-title-cta:hover {
    text-decoration: none;
}

.frontpage-title-cta img {
    margin-top: -4px;
}

/*******CALL TO ACTION SLIDE BLOCK ********/

.cta-slider {
    padding-top: 40px;
    padding-bottom: 40px;
}

/*Chrome, Safari, Opera*/
.cta-slider ::-webkit-scrollbar { width: 0 !important }

/*Firefox*/
.cta-slider { overflow: -moz-scrollbars-none; }

/*Internet Explorer*/
.cta-slider{ -ms-overflow-style: none; }


/*
.cta-slider-col .col-gutter {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
} */

.cta-slide-wrapper {
}

.cta-slide-header {
    margin-bottom: 20px;
    color: #333;
}

.cta-slide-body {
   /* font-size: 20px;
    font-weight: 300; */
    margin-bottom: 20px;
}

.cta-slide-link {
   /*color: #DB3C00;
    font-weight: 400; */
}

/******* FLEX RESOURCE BLOCK ********/
.resource-block-inner .thumb {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: background-image .1s linear;
}

.resource-block-inner .ico-article {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #DB3C00;
}

.resource-block-inner .ico-article img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.resource-block-content {
    padding: 24px;
}

.ico-play {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #DB3C00;
}

.ico-play img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.resource-block .cta-container a:hover {
    text-decoration: none;
}

/********* CALL TO ACTION SLIDER *********/
.sliderArrowWrapper {
    position: absolute;
    top: 50%;
}

.sliderArrowWrapper.left {
    left: -10px;
}

.sliderArrowWrapper.right {
    right: -10px;
}

.sliderArrowWrapper a.sliderArrow {
    display: inline-block;
    padding: 5px;
}

.sliderNav {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    text-align: center;
}

.sliderNav a {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #CCC;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-right: 15px;
}

.sliderNav a.active {
    background: #4D00C7;
}

.sliderNav a:last-child {
    margin-right: 0px;
}

@media only screen and (max-width: 768px) {
    .sliderArrowWrapper.left {
        left: 0px;
    }

    .sliderArrowWrapper.right {
        right: 0px;
    }
}

.cta-slide {
    float:left;
    /* display: inline-block; */
    /* margin-left: 17%; */
}

.cta-slider-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

#blockpage-wrapper .cta-slider-row {
    display: flex !important;
    overflow-x: hidden;
    flex-wrap: nowrap;
}

.cta-slide > div > img {
    width: 100%;
}

.cta-slide-wrapper > div > div {
    /*float: left;*/
}

.cta-slider-col .col-gutter {
    width: 100%;
}

.slider-row > div .col-gutter {
    display: flex;
    justify-content: center;
}

.slider-row {
    padding: 40px 0;
}
















/* HEADER NAV */
ul.nav-tabs .container .row div:first-child {}

ul.nav-tabs .container .row div:first-child h2 {
    font-size: 22px;
    font-weight: 400;
    color: #4A4A4A;
    margin-bottom: 10px;
}

ul.nav-tabs .container .row div:first-child p {
    font-size: 17px;
    color: #4A4A4A;
    font-weight: 400;
}

/* 404 search form*/
.searchPage .btn {
    padding: 11px 0.75rem 10px;
    border-radius: unset;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.searchPage .input-group > .form-control {
    width: unset !important;
    outline: none;
}

.col-xs-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media only screen and (max-width: 768px) {
    .frontpage-title-wrapper {
        padding-left: 0px;
        padding-right: 0px;
    }

    .rich-text-block {
        margin-bottom: 45px;
    }
}

@media only screen and (max-width: 576px) {
    .hidden-sm {
        display: none !important;
    }
}

@media only screen and (max-width: 540px) {
    .hidden-xs {
        display: none !important;
    }
}

.teamListing ul,
.teamListing ul li {
    padding-bottom: 0px;
}

.teamListing ul li p {
    margin-left: -5px;
}


.visualClear  {
    clear: both;
}

