.font-serif {
  font-family: 'Roboto Slab', serif;
}
.garvan-header {
  position: relative;
  height: 360px;
  overflow: hidden;
}
.garvan-header .container {
  position: relative;
  height: 100%;
}
.garvan-header .header-icon.isPerson {
  border: 1px solid #f00;
  border-radius: 50%;
}
.garvan-header .header-text-wrapper-ext {
  position: absolute;
  height: 100%;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.garvan-header .header-text-wrapper-ext .personInfo .position {
  font-weight: 400;
}
.garvan-header .header-text-wrapper-ext .personInfo span.team:after {
  content: ', ';
}
.garvan-header .header-text-wrapper-ext .personInfo span.team:last-child:after {
  content: '';
}
.garvan-header .header-text-wrapper-ext .social {
  margin-top: 10px;
  margin-bottom: 10px;
}
.garvan-header .header-text-wrapper-ext .social .ai,
.garvan-header .header-text-wrapper-ext .social .fa {
  font-size: 1.5rem;
}
.garvan-header .header-text-wrapper-int {
  display: flex;
  flex-direction: column;
}
/* ************* BANNER COLOR VARIATIONS *************** */
.garvan-wave-indigo .garvan-header-left {
  background-color: #4d00C7;
}
.garvan-wave-indigo .header-curve {
  fill: #4d00C7;
}
.garvan-wave-indigo .garvan-header-title,
.garvan-wave-indigo .garvan-header-subtitle,
.garvan-wave-indigo .garvan-header-bottom-line,
.garvan-wave-indigo .garvan-header-cta {
  color: white;
}
.garvan-wave-indigo .garvan-header-cta {
  background-image: url(db81eb12af18fae48ad2dbee91070547.svg);
}
.garvan-wave-grey .garvan-header-left {
  background-color: #c6c7c5;
}
.garvan-wave-grey .header-curve {
  fill: #c6c7c5;
}
.garvan-wave-grey .garvan-header-title {
  color: #4d00C7;
}
.garvan-wave-grey .garvan-header-subtitle {
  color: #000;
}
.garvan-wave-grey .garvan-header-bottom-line {
  color: #4d00C7;
}
.garvan-wave-grey .garvan-header-cta {
  color: #4d00C7;
}
.garvan-wave-neutral-1 .garvan-header-left {
  background-color: #e8e8e7;
}
.garvan-wave-neutral-1 .header-curve {
  fill: #e8e8e7;
}
.garvan-wave-neutral-1 .garvan-header-title {
  color: #4d00C7;
}
.garvan-wave-neutral-1 .garvan-header-subtitle {
  color: #000;
}
.garvan-wave-neutral-1 .garvan-header-bottom-line {
  color: #4d00C7;
}
.garvan-wave-neutral-1 .garvan-header-cta {
  color: #4d00C7;
}
.garvan-wave-neutral-2 .garvan-header-left {
  background-color: #f5f3f1;
}
.garvan-wave-neutral-2 .header-curve {
  fill: #f5f3f1;
}
.garvan-wave-neutral-2 .garvan-header-subtitle {
  color: #333;
}
.garvan-wave-neutral-2 .garvan-header-bottom-line {
  color: #4d00C7;
}
.garvan-wave-neutral-2 .garvan-header-cta {
  color: #4d00C7;
}
.garvan-wave-white .garvan-header-left {
  background-color: #f5f3f1;
}
.garvan-wave-white .header-curve {
  fill: #f5f3f1;
}
.garvan-wave-white .garvan-header-subtitle {
  color: #333;
}
.garvan-wave-white .garvan-header-bottom-line {
  color: #4d00C7;
}
.garvan-wave-white .garvan-header-cta {
  color: #4d00C7;
}
.garvan-wave-hide-dark-text .garvan-header-subtitle {
  color: #333;
}
.garvan-wave-hide-dark-text .garvan-header-bottom-line {
  color: #4d00C7;
}
.garvan-wave-hide-dark-text .garvan-header-cta {
  color: #4d00C7;
}
.garvan-wave-hide-dark-text .header-curve {
  display: none;
}
.garvan-wave-hide-dark-text .garvan-header-left {
  display: none;
}
.garvan-wave-hide-dark-text .garvan-header-right {
  width: 100%;
}
.garvan-wave-hide-white-text .garvan-header-title,
.garvan-wave-hide-white-text .garvan-header-subtitle,
.garvan-wave-hide-white-text .garvan-header-bottom-line,
.garvan-wave-hide-white-text .garvan-header-cta {
  color: white;
}
.garvan-wave-hide-white-text .garvan-header-cta {
  background-image: url(db81eb12af18fae48ad2dbee91070547.svg);
}
.garvan-wave-hide-white-text .header-curve {
  display: none;
}
.garvan-wave-hide-white-text .garvan-header-left {
  display: none;
}
.garvan-wave-hide-white-text .garvan-header-right {
  width: 100%;
}
.garvan-header-left {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40%;
}
.garvan-header-right {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header-curve {
  position: absolute;
  left: calc(40% - 1px);
  top: 0;
  bottom: 0;
  height: 100%;
  width: 400px;
}
.header-icon {
  width: 250px;
  height: 250px;
  background-position: center center;
  background-size: contain;
  position: absolute;
  top: 50px;
  right: 24px;
}
.header-dots {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 400px;
  background-image: url(b116ca2ac04bedb81faf7ec18e8ca18a.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.garvan-header-title {
  font-family: 'Roboto Slab', serif;
  font-size: 50px;
  font-weight: 200;
  line-height: 0.95;
  letter-spacing: 2px;
  width: 45%;
  /*ax-width: 500px;*/
}
.garvan-header-subtitle {
  font-size: 22px;
  line-height: 1.1;
  width: 55%;
  max-width: 500px;
}
.garvan-header-bottom-line {
  font-family: 'Roboto Slab', serif;
  font-size: 25px;
  font-weight: 800;
  line-height: 1.1;
  margin-top: 10px;
  width: 60%;
  max-width: 500px;
}
.cta-shrinker {
  display: flex;
}
.garvan-header-cta {
  font-family: 'Roboto Slab', serif;
  font-size: 22px;
  font-weight: bold;
  padding: 8px;
  margin-left: -8px;
  padding: 8px 36px 8px 8px;
  background-image: url(376a1d34be01d73fed3f156b0c7729a1.svg);
  background-repeat: no-repeat;
  background-position: right 70%;
  font-family: Roboto Slab;
  font-weight: 300;
  display: inline-block;
}
.garvan-header-menu {
  background-color: #e9e7e5;
}
.garvan-header-menu ul {
  margin: 0px;
  padding: 0px;
}
.garvan-header-menu li {
  list-style-type: none;
  display: inline-block;
  padding: 20px 30px;
  margin: 0px 10px;
  font-size: 18px;
  font-weight: 400;
}
.garvan-header-menu li a {
  color: #333;
  display: inline-block;
}
.garvan-header-menu li.active a {
  color: #4d00C7;
}
.garvan-header-menu li a:hover {
  color: #333;
  text-decoration: underline;
}
.garvan-header-menu li a.active {
  color: #4d00C7;
}
.garvan-header-menu-container {
  z-index: 1;
}
/* ********** MEDIA QUERIES ************ */
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .garvan-header {
    height: 400px;
  }
  .garvan-header .header-text-wrapper-ext {
    justify-content: flex-end;
    padding-bottom: 48px;
  }
  .garvan-header-left {
    width: 10%;
  }
  .garvan-header-right {
    width: 90%;
  }
  .header-curve {
    left: calc(10% - 1px);
    width: 400px;
  }
  .garvan-header-title {
    font-size: 28px;
    margin: 8px 0;
    width: 70%;
    font-weight: 300;
  }
  .garvan-header-subtitle {
    display: none;
  }
  .garvan-header-bottom-line {
    display: none;
  }
  .garvan-header-cta {
    font-size: 18px;
  }
  .header-dots {
    height: 250px;
    width: 250px;
    left: 30%;
  }
  .header-icon {
    left: auto;
    right: 20px;
    top: 40px;
    width: 125px;
    height: 125px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .garvan-header {
    height: 400px;
  }
  .garvan-header-left {
    width: 30%;
  }
  .garvan-header-right {
    width: 70%;
  }
  .header-curve {
    left: calc(30% - 1px);
    width: 400px;
  }
  .header-dots {
    height: 250px;
    width: 250px;
    left: 40%;
  }
  .garvan-header-title {
    font-size: 36px;
    margin: 8px 0;
    width: 75%;
    font-weight: 300;
  }
  .garvan-header-subtitle {
    display: none;
  }
  .garvan-header-bottom-line {
    font-size: 18px;
    width: 80%;
  }
  .garvan-header-cta {
    font-size: 18px;
  }
  .header-icon {
    left: auto;
    right: 20px;
    top: 20px;
    width: 150px;
    height: 150px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .garvan-header {
    height: 320px;
  }
  .garvan-header-left {
    width: 50%;
  }
  .garvan-header-right {
    width: 50%;
  }
  .header-curve {
    left: calc(50% - 1px);
    width: 320px;
  }
  .garvan-header-title {
    font-size: 40px;
    font-weight: 300;
    margin: 8px 0;
    width: 70%;
  }
  .garvan-header-subtitle {
    width: 80%;
    font-size: 18px;
  }
  .garvan-header-bottom-line {
    font-size: 18px;
    width: 80%;
  }
  .garvan-header-cta {
    font-size: 18px;
  }
  .header-dots {
    height: 250px;
    width: 250px;
    left: 60%;
  }
  .header-icon {
    left: auto;
    right: 0px;
    top: 20px;
    width: 150px;
    height: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .garvan-header-menu {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .garvan-header-menu li {
    padding: 0px 5px;
    font-size: 16px;
    display: block;
    border-top: 1px solid #ccc;
  }
  .garvan-header-menu li a {
    display: block;
    padding: 20px 5px;
  }
  .garvan-header-menu li:last-child {
    border-bottom: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social .ai,
  .social .fa {
    font-size: 22px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-text-wrapper-ext {
    padding-top: 100px !important;
  }
  .garvan-header {
    height: 250px;
  }
  .garvan-header-left {
    width: 60%;
  }
  .garvan-header-right {
    width: 40%;
  }
  .header-curve {
    left: calc(60% - 1px);
    width: 250px;
  }
  .garvan-header-title {
    font-size: 32px;
    font-weight: 300;
    margin: 8px 0;
    width: 70%;
  }
  .garvan-header-subtitle {
    width: 70%;
    font-size: 16px;
  }
  .garvan-header-bottom-line {
    font-size: 16px;
    width: 80%;
  }
  .garvan-header-cta {
    font-size: 16px;
  }
  .header-dots {
    height: 250px;
    width: 250px;
    left: 70%;
  }
  .header-icon {
    left: auto;
    right: 20px;
    top: 20px;
    width: 150px;
    height: 150px;
  }
  .garvan-header-menu {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .garvan-header-menu li {
    padding: 0px 5px;
    font-size: 16px;
    display: block;
    border-top: 1px solid #ccc;
  }
  .garvan-header-menu li a {
    display: block;
    padding: 20px 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .garvan-header-menu {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .garvan-header-menu li {
    padding: 0px 5px;
    font-size: 16px;
    display: block;
    border-top: 1px solid #ccc;
  }
  .garvan-header-menu li a {
    display: block;
    padding: 20px 5px;
  }
}
@media only screen and (max-width: 1021px) and (min-width: 992px) {
  .header-icon {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (min-width: 992px) {
  .garvan-header-tall {
    height: 600px;
  }
  .garvan-header-tall .header-curve {
    width: 600px;
  }
  .garvan-header-tall .header-dots {
    width: 700px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1299px) {
  .garvan-header-menu li {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1440px) {
  .garvan-header-menu li {
    padding: 20px 7px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1635px) {
  .garvan-header-menu li {
    padding: 20px 18px;
  }
}
.cta-slider {
  padding-top: 40px;
  padding-bottom: 40px;
}
.cta-slider-col .col-gutter {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.cta-slider-col .col-gutter::-webkit-scrollbar {
  display: none;
}
.cta-slider-col .col-gutter {
  overflow: -moz-scrollbars-none;
}
.cta-slider-col .layout-portlet {
  flex: 0 0 auto;
}
/*List view*/
.list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.search-result-list {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  margin: 0 20px 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
  position: relative;
  display: flex;
}
.search-result-list .tileImage {
  margin: 10px 20px;
}
.search-result-list .news-entry-body {
  padding: 0 20px;
  flex-grow: 1;
}
.search-result-list .title-and-date {
  display: flex;
  justify-content: space-between;
  align-items: baselinet;
}
.search-result-list h3.tileHeadline {
  line-height: 1.3em;
  font-weight: 300;
}
.search-result-list h3.tileHeadline a {
  color: #4D00C7;
  text-decoration: none;
}
.search-result-list .documentByLine {
  color: #DB3C00;
  float: right;
}
.search-result-list .description-and-arrow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.search-result-list .news-entry-description {
  font-weight: 300;
}
.search-result-list .news-readmore {
  display: block;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin-left: 20px;
}
.search-result-promoted {
  border: 4px solid #D0E0F0;
  padding: 8px;
  border-radius: 8px;
}
/* Generic random content icon generated via obj/@@icon-url */
.contentIcon {
  background-position: center center;
  background-size: cover;
  background-color: #E0E8F0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
}
/* #22825 - News item tiles responsiveness */
.latestNews .card {
  color: #000;
  text-decoration: none;
  background: #f5f3f1;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0);
  transition: all, 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}
.latestNews .card:hover {
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.05);
}
.latestNews .card-text {
  font-size: 1em;
  padding: 0.5em 0.5em 0;
  flex-grow: 1;
  flex-shrink: 1;
}
.latestNews .details {
  font-size: 1em;
  font-family: 'Roboto Slab', sans-serif;
  color: #dc3c00;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 0.5em;
  justify-self: end;
}
@media (max-width: 300px) {
  .latestNews .details {
    font-size: 0.6em;
    line-height: 1.2em;
  }
}
.latestNews .details .more-link {
  margin: 0;
}
/* / END OF #22825 - News item tiles responsiveness */
