#logo {
  position: absolute;
  top: 17px;
  left: 16px;
}
/* line 426, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#logo a {
  display: inline-block;

}
/* line 430, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#logo a:focus {
  overflow: visible;
  outline: none;
}
/* line 437, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#logo img {
  margin: 0;

}

.main-header-int {

    margin: 0 auto;
    position: relative;
}

.main-header-right {
  position: absolute;
  right: 16px;
  top: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  margin-left: 60px;
  transition: box-shadow 0.5s;
  z-index: 10;
}

.nav-tabs h4 {text-align:left; font-size: 18px; font-weight: 400; color: #4A4A4A; margin-bottom: 10px;}
.nav-tabs h3 {font-size: 22px; font-weight: 400; color: #4A4A4A; margin-bottom: 10px;}


#main-header .desktop-search-container .btn {
  color: #335a81;
  font-size: 18px;
  line-height: 42px;
  width: 40px;
  height: 40px;
  margin-right: 2px;
  padding: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-shadow: none;
}
/* line 302, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container.small .btn {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
/* line 309, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search {
  text-align: left;
  white-space: nowrap;
  width: auto;
  color: #000;
  position: absolute;
  right: 100%;
  top: 0;
  margin-right: 30px;
  padding: 0;
  border-radius: 2px;
  background-color: transparent;
  transition: all 0.3s;
  /* Placeholder styles */
}
/* line 324, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search.expanded {
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}
/* line 330, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search ::-webkit-input-placeholder {
  /* WebKit browsers */
  opacity: 1;
  color: inherit;
}
/* line 333, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  opacity: 1;
  color: inherit;
}
/* line 336, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 1;
  color: inherit;
}
/* line 339, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  opacity: 1;
  color: inherit;
}
/* line 343, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search div,
#main-header .desktop-search-container .search form {
  display: inline-block;
  margin: 0;
}
/* line 349, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search div {
  color: inherit;
  padding: 0 11px;
  position: absolute;
  right: 0;
}
/* line 356, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search div:hover,
#main-header .desktop-search-container .search div:focus,
#main-header .desktop-search-container .search div:active {
  text-decoration: none;
}
/* line 364, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search form {
  margin: 0;
  padding: 3px 48px 10px 20px;
  width: 0px;
}
/* line 371, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search input {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  color: #fff;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 16px;
  color: #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  border-radius: 0;
}
/* line 385, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header .desktop-search-container .search input:focus {
  background: transparent;
}

#expand-search {
    outline: none;
}

.visuallyhidden {
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/* Allow focus when navigated to via the keyboard */
/* line 3746, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  background-color: #222;
  color: #fff !important;
}



#section-nav-wrapper {
    padding-top: 25px;
    padding-bottom: 10px;
}


.mobile-tabs {
    display: none;
}


#section-nav {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
}



#section-nav {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
}


.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
/* line 15, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav > li {
  display: block;
}
/* line 18, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav > li > a {
  position: relative;
  display: block;
  word-wrap: break-word;
white-space: normal;
}
/* line 21, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
/* line 29, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav > li.disabled > a {
  color: #777777;
}
/* line 32, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}
/* line 44, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #337ab7;
}
/* line 57, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
/* line 64, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav > li > a > img {
  max-width: none;
}
/* line 74, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs {
  border-bottom: 1px solid #dddddd;
}
/* line 76, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
/* line 82, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
/* line 87, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #dddddd;
}
/* line 94, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-bottom-color: transparent;
  cursor: default;
}
/* line 106, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}
/* line 160, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs.nav-justified > li {
  float: none;
}
/* line 162, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}
/* line 168, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 768px) {
  /* line 174, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  /* line 177, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}
/* line 190, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}
/* line 196, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #dddddd;
}

@media (min-width: 768px) {
  /* line 203, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #dddddd;
    border-radius: 4px 4px 0 0;
  }
  /* line 207, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #ffffff;
  }
}
/* line 116, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-pills > li {
  float: left;
}
/* line 120, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-pills > li > a {
  border-radius: 4px;
}
/* line 123, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-pills > li + li {
  margin-left: 2px;
}
/* line 129, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #ffffff;
  background-color: #337ab7;
}
/* line 142, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-stacked > li {
  float: none;
}
/* line 144, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-stacked > li + li {
  margin-left: 0;
}
/* line 157, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-justified {
  width: 100%;
}
/* line 160, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-justified > li {
  float: none;
}
/* line 162, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}
/* line 168, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 768px) {
  /* line 174, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  /* line 177, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-justified > li > a {
    margin-bottom: 0;
  }
}
/* line 187, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs-justified {
  border-bottom: 0;
}
/* line 190, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}
/* line 196, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
  border: 1px solid #dddddd;
}
@media (min-width: 768px) {
  /* line 203, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #dddddd;
    border-radius: 4px 4px 0 0;
  }
  /* line 207, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #ffffff;
  }
}
/* line 221, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.tab-content > .tab-pane {
  display: none;
}
/* line 224, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.tab-content > .active {
  display: block;
}
/* line 234, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navs.less */
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
/* line 11, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
@media (min-width: 768px) {
  /* line 20, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar {
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  /* line 34, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-header {
    float: left;
  }
}
/* line 50, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-collapse {
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
}
/* line 59, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-collapse.in {
  overflow-y: auto;
}
@media (min-width: 768px) {
  /* line 63, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  /* line 68, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  /* line 75, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-collapse.in {
    overflow-y: visible;
  }
  /* line 81, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}
/* line 92, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}
@media (max-device-width: 480px) and (orientation: landscape) {
  /* line 95, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}
/* line 108, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  /* line 113, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
/* line 128, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}
@media (min-width: 768px) {
  /* line 132, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-static-top {
    border-radius: 0;
  }
}
/* line 138, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
@media (min-width: 768px) {
  /* line 146, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
}
/* line 150, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
/* line 154, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}
/* line 163, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-brand {
  float: left;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  height: 50px;
}
/* line 170, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
/* line 175, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-brand > img {
  display: block;
}
@media (min-width: 768px) {
  /* line 180, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}
/* line 193, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
/* line 206, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-toggle:focus {
  outline: 0;
}
/* line 211, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
/* line 217, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}
@media (min-width: 768px) {
  /* line 221, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-toggle {
    display: none;
  }
}
/* line 232, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-nav {
  margin: 7.5px -15px;
}
/* line 235, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
@media (max-width: 767px) {
  /* line 243, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  /* line 251, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  /* line 255, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  /* line 257, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }


  #section-nav li.expanded .section {
    display: block;
  }

  #section-nav li.expanded .menuArrow {
    transform: rotate(90deg);
  }

}
@media (min-width: 768px) {
  /* line 266, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-nav {
    float: left;
    margin: 0;
  }
  /* line 270, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-nav > li {
    float: left;
  }
  /* line 272, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
/* line 286, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-form {
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  margin-top: 8px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  /* line 479, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  /* line 486, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  /* line 493, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .form-control-static {
    display: inline-block;
  }
  /* line 497, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  /* line 501, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn,
  .navbar-form .input-group .form-control {
    width: auto;
  }
  /* line 509, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .input-group > .form-control {
    width: 100%;
  }
  /* line 513, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  /* line 520, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .radio,
  .navbar-form .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  /* line 527, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .radio label,
  .navbar-form .checkbox label {
    padding-left: 0;
  }
  /* line 531, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .radio input[type="radio"],
  .navbar-form .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  /* line 538, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/forms.less */
  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}
@media (max-width: 767px) {
  /* line 299, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-form .form-group {
    margin-bottom: 5px;
  }
  /* line 302, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  /* line 312, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-form {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
/* line 327, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
/* line 332, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
/* line 343, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}
/* line 346, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* line 349, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}
/* line 359, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  /* line 362, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-text {
    float: left;
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (min-width: 768px) {
  /* line 379, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-left {
    float: left !important;
  }
  /* line 380, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
  /* line 384, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}
/* line 395, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}
/* line 399, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-brand {
  color: #777777;
}
/* line 401, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}
/* line 408, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-text {
  color: #777777;
}
/* line 413, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-nav > li > a {
  color: #777777;
}
/* line 416, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #333333;
  background-color: transparent;
}
/* line 423, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555555;
  background-color: #e7e7e7;
}
/* line 431, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #cccccc;
  background-color: transparent;
}
/* line 440, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-toggle {
  border-color: #dddddd;
}
/* line 442, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #dddddd;
}
/* line 446, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-toggle .icon-bar {
  background-color: #888888;
}
/* line 451, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
}
/* line 460, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: #e7e7e7;
  color: #555555;
}
@media (max-width: 767px) {
  /* line 471, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777777;
  }
  /* line 473, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333333;
    background-color: transparent;
  }
  /* line 480, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555555;
    background-color: #e7e7e7;
  }
  /* line 488, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
  }
}
/* line 504, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-link {
  color: #777777;
}
/* line 506, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .navbar-link:hover {
  color: #333333;
}
/* line 511, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .btn-link {
  color: #777777;
}
/* line 513, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #333333;
}
/* line 519, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #cccccc;
}
/* line 529, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse {
  background-color: #222222;
  border-color: #080808;
}
/* line 533, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}
/* line 535, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: #ffffff;
  background-color: transparent;
}
/* line 542, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-text {
  color: #9d9d9d;
}
/* line 547, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}
/* line 550, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: #ffffff;
  background-color: transparent;
}
/* line 557, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: #080808;
}
/* line 565, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444444;
  background-color: transparent;
}
/* line 575, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-toggle {
  border-color: #333333;
}
/* line 577, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #333333;
}
/* line 581, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #ffffff;
}
/* line 586, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010;
}
/* line 594, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #080808;
  color: #ffffff;
}
@media (max-width: 767px) {
  /* line 605, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }
  /* line 608, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }
  /* line 611, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }
  /* line 613, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #ffffff;
    background-color: transparent;
  }
  /* line 620, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #ffffff;
    background-color: #080808;
  }
  /* line 628, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444444;
    background-color: transparent;
  }
}
/* line 639, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-link {
  color: #9d9d9d;
}
/* line 641, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .navbar-link:hover {
  color: #ffffff;
}
/* line 646, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .btn-link {
  color: #9d9d9d;
}
/* line 648, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
  color: #ffffff;
}
/* line 654, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/navbar.less */
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444444;
}

#section-dropdowns-wrapper {
  position: relative;
  z-index: 1000;
}
/* line 14, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-dropdowns-wrapper:before,
#section-dropdowns-wrapper:after {
  content: " ";
  display: table;
}
/* line 19, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-dropdowns-wrapper:after {
  clear: both;
}
/* line 14, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-dropdowns-wrapper:before,
#section-dropdowns-wrapper:after {
  content: " ";
  display: table;
}
/* line 19, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-dropdowns-wrapper:after {
  clear: both;
}
/* line 698, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav {
  /* > .container
    {
        position: relative;
    } */
}
/* line 708, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section {
  padding: 25px 0;
  display: none;
  color: #003263;
  width: 100%;
  background: #f5f3f1;
  position: absolute;
  right: 0;
  z-index: 60;
}

#section-nav .section .container .row .col-md-4:first-child {
  height: max-content;
}

#section-nav .section .container .row .col-md-4:first-child .col-md-2 {
  float: right;
  width: 40%;
  height: 100%;
}

#section-nav .section .container .row .col-md-4:first-child .col-md-2 img {
  width: 100%;
  float: right;
}

@media (max-width: 768px) {
  #section-nav .section {
    margin-left: -10px;
    padding-left: 10px;
  }
}

/* line 14, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-nav .section:before,
#section-nav .section:after {
  content: " ";
  display: table;
}
/* line 19, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-nav .section:after {
  clear: both;
}
/* line 14, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-nav .section:before,
#section-nav .section:after {
  content: " ";
  display: table;
}
/* line 19, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/mixins/clearfix.less */
#section-nav .section:after {
  clear: both;
}
/* line 720, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section .nav {
  margin-bottom: 0;
  display:block;
}
/* line 724, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section .nav li {
  font-weight: 300;
  font-size: 17px;
}
/* line 728, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section .nav li a,
#section-nav .section .nav li .heading {
  color: #333;
  line-height: normal;
  padding: 6px 2px;
}
/* line 738, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section .nav li a:hover {
  background-color: transparent;
  color: #DB3C00 !important;
}
/* line 746, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section .nav li .heading {
  display: block;
  font-weight: bold;
}
/* line 754, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section .feature,
#section-nav .section .feature * {
  color: #333;
}
/* line 759, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .section .section-edit {
  position: absolute;
  left: 0;
  bottom: 0;
  color: inherit;
  margin: 5px;
}
@media (max-width: 768px) {
  /* line 775, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
  #section-dropdowns .section .feature,
  #section-dropdowns .section .feature * {
    display: none;
  }
}
/* line 783, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  background-color: inherit;
}
/* line 791, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  background-color: inherit;
}

/* line 593, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs {
  border: none;
  margin-bottom: 0;
  color: #f5f3f1;
}
/* line 598, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs > li {
  /*text-align: center;*/
  border: none;
  margin-bottom: 0px;
  margin-left: 10px;
}
/* line 605, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs > li > a {
  padding: 8px 17px;
  margin: 0;
  border: none;
  color: #4A4A4A;
  font-weight: 300;
  border-radius: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}
/* line 616, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs > li > a:hover,
#section-nav .nav-tabs > li > a:focus,
#section-nav .nav-tabs > li > a.active-nav {
  color: #4A4A4A;
  background-color: #f5f3f1 !important;
  border: 0;
}

/* line 624, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs > li > a:after {
  content: "|";
  position: absolute;
  top: 7px;
  padding-left: 20px;
}
/* line 634, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs > li:last-child > a:after {
  content: "";
}
/* line 641, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs .links li {
  text-align: left;
}
/* line 646, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#section-nav .nav-tabs .feature {
  text-align: left;
}
/* line 684, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
.active-nav {
  color: #333 !important;
  /*background-color: #e5eaee !important;*/
  background-color: #f5f3f1 !important;
  border: 0 !important;
}


/* line 193, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
.plone-toolbar-left #main-header {
  left: 60px;
}
/* line 198, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
.plone-toolbar-left.plone-toolbar-left-expanded #main-header {
  left: 120px;
}
/* line 202, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
/*.main-header-int {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  padding-left:15px; padding-right:15px;
}*/
/* line 218, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
.ms-skin-default .ms-nav-next,
.ms-skin-default .ms-nav-prev {
  z-index: 3;
}
/* line 222, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/garvan.less */
#main-header {
  background-color: white;
  z-index: 4;
}


.nav-caret {
    display: none;
}


#section-search {
    display: none;
    padding: 16px 32px;
}
.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}


.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.nav > li {display: block;}

.section-nav-fixed {
   /* box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.2);*/
   box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px;
}

.desktop-search-container input {height:34px; vertical-align:top;}

.section-search .btn-primary {background-color: #dc3c00;}
.section-search .btn-primary:hover {background-color: #4d00c7;}

@media (max-width: 1020px) {
  /* line 792, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  body {
    padding-top: 0;
  }
  /* line 796, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #main-header {
    position: static;
  }
  /* line 800, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .burger {
    display: block;
    width: 100px;
  }
  /* line 808, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .nav-tabs {
    border-top: 1px solid rgba(0, 0, 0, 0.25) !important;
    margin-top: 8px;
  }
  /* line 815, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .nav-tabs > li {
    width: 100%;
    /* display: flex;
    justify-content: space-between; */
  }
  /* line 820, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .nav-caret {
    display: flex;
	cursor:pointer;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 64px;
    float: right;
    /*padding-right: 10px;*/
  }

  /* line 825, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #section-nav .nav-tabs > li > a {
    padding: 8px;
    /*font-size: 130%;*/
    float: left;
    padding-left: 10px;
    padding-right: 30px;
  }
  /* line 835, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #section-nav .nav-tabs > li > a:after {
      content: "" !important;
  }

  span.menuArrow {
    transition: all 0.3s;
  }

  span.menuArrow:after {
      content: "\F105" !important;
      color: #4A4A4A;
      font-family: FontAwesome;
      display: inline-block;
      height: 20px;
      width: 20px;
      text-align: center;

  }

  /* line 839, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #section-nav .nav-tabs > li {
    width: 100%;
    padding-left: 25px;
    margin-left: 0 !important;
  }
  /* line 848, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #section-nav .section {
    position: relative;
    z-index: 0;
    margin-top: 35px;
  }
  /* line 856, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .section-edit {
    display: none;
  }
  /* line 860, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #section-nav .col-md-3 {
    width: 100% !important;
  }
  /* line 867, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #section-nav-wrapper {
    padding-top: 70px;
  }
  /* line 871, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .mobile-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  /* line 880, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .mobile-tab {
    display: block;
    padding: 8px 16px;
    position: relative;
    font-weight: bold;
    color: #4D00C7;
  }
  /* line 889, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .mobile-tab:hover,
  .mobile-tab:active,
  .mobile-tab:focus {
    text-decoration: none;
    outline: none;
  }
  /* line 897, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .mobile-tab-expandable {
    padding-right: 24px;
  }
  /* line 899, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .mobile-tab-expandable:after {
    position: absolute;
    right: 8px;
    top: 14px;
    font-size: 10px;
    content: '\25BC';
  }
  /* line 908, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .desktop-search-container {
    display: none;
  }
  /* line 916, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  .donate-btn {
    font-size: 19px;
  }
  /* line 921, http://plone.garvan.org.au:8083/vhosts/www.garvan.org.au/++theme++less/responsive.less */
  #section-nav {
    display: none;
  }

  .nav-tabs {

    border-top: 1px solid rgba(0, 0, 0, 0.25) !important;
    margin-top: 8px;

}

  .nav-tabs .col-sm-1, .nav-tabs .col-sm-2, .nav-tabs .col-sm-3, .nav-tabs .col-sm-4, .nav-tabs .col-sm-5, .nav-tabs .col-sm-6 {-webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}


  .section-search input[type="text"], .section-search input[type="number"], .section-search input[type="email"], .section-search input[type="password"], textarea, select {

    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #696969;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

}

	.section-search .input-group input[type=text] {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.section-search .input-group input[type=submit] {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

.input-group-btn {

    position: relative;
    font-size: 0;
    white-space: nowrap;

}

.input-group-addon, .input-group-btn {

    width: 1%;
    white-space: nowrap;
    vertical-align: middle;

}

.input-group-addon, .input-group-btn, .input-group .form-control {

    display: table-cell;

}

.section-search .standalone, .section-search .context, .section-search .destructive, .section-search [type="submit"], .section-search button {

    display: inline-block;
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 1px rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.donate-btn {

    font-size: 19px;

}
#logo {top:11px; left:15px;}
.main-header-right {top: 16px !important;}
.main-header-int {max-width:inherit !important}

.mobile-tab {font-size:18px; font-family: 'Roboto', sans-serif; font-weight:500;}

}

