#mc_embed_signup {
    background: #4D00C7; /*url('../images/header-dots.png') no-repeat 30% 40% */
    clear: left;
    padding: 20px 0px;
    margin-bottom: 60px;
}

#mc_embed_signup h2 {
    color: #FFFFFF;
    text-align: center;
    font-weight: normal;
    font-size: 35px;
}

#mc_embed_signup p {
    color: #FFFFFF;
    padding: 10px 0px;
    /*text-align: center;*/
    /*margin-bottom: 50px;*/
    /*font-size: 25px;*/
}

#mc_embed_signup p b {
    font-weight: 600;
}

#mc_embed_signup .mc-field-group {
    position: relative;
    border: 0px solid #f00;
    /*margin: 0px auto;*/
}

#mc_embed_signup .mc-field-group input#mce-EMAIL {
    padding: 20px 80px 20px 20px;
    text-indent: 0px;
    outline: none;
    border-radius: 0px;
    display: inline-block;
    font-size: 0.9em;
    color: #333333;
    font-family: 'Roboto', sans-serif;
}

#mc_embed_signup .mc-field-group input#mce-EMAIL::placeholder {
    color: #333333;
}

#mc_embed_signup .mc-field-group input[type=submit] {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding: 0px 10px;
    border: 0px solid #FFFFFF;
    background: #02d5d2;
    color: #4d00c7;
    font-weight: bold;
}

div#mce-responses {
    float: left;
    border: 1px solid #f010;
    display: block;
    width: 100%;
}

div#mce-responses #mce-success-response {
    color: #FFFFFF;
    float: left;
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    margin: 10px 0px;
    border-radius: 5px;
    font-weight: bold;
    background: #6dc6da;
    font-weight: normal !important;
}

#mc_embed_signup div.mce_inline_error,
div#mce-responses #mce-error-response {
    font-size: 14px;
    float: left;
    background: #CD0028 !important;
    border-radius: 5px;
    padding: 5px 10px !important;
    margin: 10px 0px !important;
    color: #FFFFFF;
    width: 100%;
    text-align: center;
    font-weight: normal !important;
}

div#mce-responses #mce-error-response a {
    color: #02d5d2;
}


@media only screen and (max-width: 1024px) {
    #mc_embed_signup p {
        text-align: center;
    }

    #mc_embed_signup .mc-field-group {
        width: 80%;
        margin: 0px auto;
    }
}

@media only screen and (max-width: 769px) {
    #mc_embed_signup .mc-field-group {
        width: 90%;
    }
}