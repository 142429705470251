.pfg-form {
	border: 0px solid #f00;
	padding: 15px;
	font-size: 16px;
}

.pfg-form .formQuestion {
}

.pfg-form .formQuestion.label {
	border-bottom: 1px solid #ccc;
	color: #4D00C7;
	font-weight: bold;
	padding: 15px 0px;
	font-size: 18px;
}

.pfg-form .field,
.pfg-form .formControls {
	padding: 5px 15px;
}

/*.pfg-form .formButton {
	border: 0;
	box-shadow: 1px 1px 1px #CCC;
	border-radius: 6px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	padding: 10px 30px;
	margin-left: 0;
	height: auto;
	background-color: #D94C00;
	text-transform: uppercase;
	margin-top: 10px;
}*/

.pfg-form input[type="text"],
.pfg-form input[type="number"],
.pfg-form input[type="email"],
.pfg-form input[type="password"],
.pfg-form textarea,
.pfg-form select {
	font-size: 16px;
	padding: 10px;
}

.pfg-form textarea {
	min-height: 100px;
}

#formMessageNotif {
	padding: 5px 30px;
	color: #f00;
	font-weight: bold;
}

#formMessageNotif.success {
	color: #4D00C7 !important;
}

.pfg-form .ArchetypesLabelWidget .formQuestion.label {
	border-bottom: 1px solid #cccccc;
	font-weight: 400;
	font-size: 20px;
	clear: both;
}

.pfg-form .field {
	font-size: 16px;
}

.pfg-form ::placeholder {
	color: #cccccc;
}

.pfg-form .formHelp {
	display: block;
	font-size: 12px;
}

.pfg-form .required::after, span.required::after {
    content: "*";
    color: #db3c00;
    font-size: 16px;
    font-weight: 300;
    top: 0;
}